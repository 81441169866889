.footer {
    $p: &;
    --footer-gap: 2rem;
    background-color: var(--color-scale-100);
    color: var(--color-scale-25);

    @at-root :root[data-theme='dark'] & {
        background-color: darken($gray-darkest, 1.5%) !important;
    }

    @include mq(md) {
        gap: 3rem;
    }

    a {
        color: $gray-lightest;
    }

    &__inner {
        padding-top: $spacing-y;
        padding-bottom: $spacing-y;
        padding-top: var(--spacing-y);
        padding-bottom: var(--spacing-y);
        display: flex;
        flex-direction: column;
        gap: var(--footer-gap);
    }

    &__top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //align-items: center;

        @include mq(lg) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__brand {
        .logo__type {
            fill: $gray-lightest;
        }
    }

    &__middle {
        &.vers--links {
            display: flex;
            flex-direction: column;
            gap: var(--footer-gap);

            @include mq(lg) {
                flex-direction: row;
            }
        }

        &.vers--about {
            font-size: 0.875em;
            border: 1px solid hsla(0, 0%, 100%, 0.1);
            border-radius: 2px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            p,
            address {
                margin-block-end: 0;
            }

            p + p {
                margin-block-start: 0.5em;
            }

            @include mq(md) {
                padding: 1.5rem;
                gap: 1.5rem;
                flex-direction: row;

                & > * {
                    flex: 1;
                }

                address {
                    text-align: end;
                }
            }
        }
    }

    &__nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--footer-gap);

        @include mq(md) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include mq(lg) {
            display: contents;
        }

        &-label {
            font-weight: 600;
            opacity: 0.5;
            line-height: 1;
            margin-block-end: 1em;

            @include mq(md) {
                margin-block-end: 1.5em;
            }
        }

        &-column {
            @include mq(lg) {
                flex: 1;
            }
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
        }
        &__item {
            display: flex;
            align-items: center;
        }
        &__link {
            display: block;
            text-decoration: none;

            @include hover-focus {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    &__social {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        @include mq(lg) {
            flex: 1;
        }

        .social-link {
            background-color: hsla(0, 0%, 100%, 0.025);
            padding: 1rem;
            font-size: 0.875em;
            gap: 0.5em;
            line-height: 1;
            display: flex;
            align-items: center;
            border-radius: 2px;

            @include mq(lg) {
                justify-content: end;
            }

            .text {
                transform: translateY(1px);
            }

            svg {
                display: inline-block;
                font-size: 1em;
            }

            &.vers--darkmode-button {
                button {
                    @include button-reset;
                    filter: drop-shadow(0px 0px 1px #26ffae)
                        drop-shadow(
                            0px 0px 3px transparentize($brand-secondary, 0.35)
                        )
                        drop-shadow(
                            0px 0px 6px transparentize($brand-secondary, 0.33)
                        )
                        drop-shadow(
                            0px 0px 20px transparentize($brand-secondary, 0.7)
                        );

                    .toggle {
                        display: inline-flex;
                        width: 2.5em;
                        height: 1.25em;
                        //background-color: hsla(0, 0%, 0%, .125);
                        border: 2px solid #fff;
                        border-radius: 1em;
                        transform: translateY(3px);
                        margin-inline-start: 0.5em;
                        position: relative;

                        &::before {
                            --knob-spacing: 2px;
                            content: '';
                            position: absolute;
                            top: 50%;
                            inset-inline-start: var(--knob-spacing);
                            transform: translateY(-50%);
                            height: calc(100% - (var(--knob-spacing) * 2));
                            aspect-ratio: 1;
                            //background-color: hsla(0, 0%, 100%, .1);
                            background-color: #fff;
                            border-radius: 50%;
                        }
                    }

                    &[aria-checked='true'] {
                        .toggle::before {
                            inset-inline-start: auto;
                            inset-inline-end: var(--knob-spacing);
                        }
                    }
                }
            }
        }
    }

    &__about {
        p {
            text-wrap: balance;
            margin-block-end: 0;

            &.vers--claim {
                font-weight: 600;
                margin-block-end: 0.5em;
                max-width: 35ch;
            }
        }
    }

    &__bottom {
        @include mq(md) {
            text-align: center;
        }

        .google-rating a {
            font-size: 0.75em;
            width: auto;
            border: none;
            display: inline-flex;
            align-items: center;
            gap: 1rem;

            .stars {
                display: inline-block;
                line-height: 1;

                svg {
                    font-size: 1em;
                    height: 1.125em;
                    width: auto;
                }
            }
        }
    }
}
