//--------------------
// SCSS MIXINS
//--------------------

//Bootstrap Mixins
@import '~bootstrap/scss/_mixins.scss';

// shorthand for media-breakpoint-up
@mixin mq($name) {
    @include media-breakpoint-up($name) {
        @content;
    }
}

// Custom MQ for max-widths.
// applies to all widths below the given breakpoint
// i.e. mq-down(md, (md: 768px)) = max-width: 767px
@mixin mq-down($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (max-width: ($min - 1px)) {
            @content;
        }
    } @else {
        @content;
    }
}

// Text Hyphenation
@mixin hyphenate() {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

// Text Truncation
@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flex-center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin coverall() {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@mixin scrollable() {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

@mixin list-reset() {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@mixin link-reset() {
    color: inherit !important;
    text-decoration: none;
}

@mixin button-reset() {
    border: 0;
    padding: 0;
    background-color: transparent;
}

@mixin stretched-link() {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        pointer-events: auto;
        background-color: rgba(0, 0, 0, 0);
    }
}

@mixin neon-glow($color: currentColor) {
    color: #fff;
    text-shadow: 0 0 0.033em #fff, 0 0 0.08em #fff, 0 0 0.1em $color,
        0 0 0.2em $color, 0 0 0.3em $color, 0 0 1em $color, 0 0 1.5em $color;
}
