.list-things {
    @include flexible-list();
    --flexible-list-columns: var(--things-per-row, 3);

    &__item {
        & > * {
            height: 100%;
        }
    }
}
