/* prettier-ignore */
@mixin color-scheme-light {
    --color-scale-0: #{$gray-lightest};
    --color-scale-15: #{$gray-lighter};
    --color-scale-25: #{$gray-light};
    --color-scale-25-offset: #dfe9f0;
    --color-scale-50: #{$gray};
    --color-scale-75: #{$gray-dark};
    --color-scale-100: #{$gray-darkest};

    --color-brand-primary: #{$brand-primary};
    --color-brand-primary-offset: #{darken($brand-primary, 5%)};
    --color-brand-secondary: #{$brand-secondary};
    --color-brand-secondary-offset: #{darken($brand-secondary, 5%)};
    --color-brand-tertiary: #{$brand-tertiary};
    --color-brand-tertiary-offset: #{darken($brand-tertiary, 5%)};

    --gradient-brand-primary: linear-gradient(45deg, #{$brand-primary} 0%, #af80c6 100%);
    --gradient-brand-secondary: linear-gradient(45deg, #47bdb8 0%, #{$brand-secondary} 100%);
    --gradient-brand-tertiary: linear-gradient(45deg, #{$brand-tertiary} 0%, #f38d7d 100%);
}

/* prettier-ignore */
@mixin color-scheme-dark {
    --color-scale-0: #001e2f;
    --color-scale-15: #242f38;
    --color-scale-25: #143044;
    --color-scale-25-offset: #183b54;
    --color-scale-50: #576975;
    --color-scale-75: #e6f1f8;
    --color-scale-100: #fff;

    --color-brand-primary: #7a27ff;
    --color-brand-primary-offset: #ae7cff;
    --color-brand-secondary: #26ffae;
    --color-brand-secondary-offset: #91ffd7;
    --color-brand-tertiary: #ed667b;
    --color-brand-tertiary-offset: #{lighten($brand-tertiary, 5%)};

    --gradient-brand-primary: linear-gradient(45deg, #6e3398 0%, #6b5aed 100%);
    --gradient-brand-secondary: linear-gradient(45deg,#47bdb8 0%,#26ffae 100%);
    --gradient-brand-tertiary: linear-gradient(45deg, #bb3d50 0%, #ed667b 100%);
}

:root {
    @include color-scheme-light;
    --spacing-x: 1rem;
    --spacing-y: 2rem;

    @include mq(md) {
        --spacing-x: 4rem;
        --spacing-y: 4rem;
    }
}

:root[data-theme='dark'] {
    @include color-scheme-dark;
}

// :root:not([data-theme]) {
//     @media (prefers-color-scheme: dark) {
//         @include color-scheme-dark;
//     }
// }
