$section-padding: 4rem;

.section {
    $p: &;
    position: relative;

    --section-spacing: 1.5rem;

    @include mq(md) {
        --section-spacing: 2rem;
    }

    @include mq(lg) {
        --section-spacing: 3rem;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -12px;
        left: 0;
        width: 50%;
        height: 12px;
    }

    &__inner {
        padding-top: $section-padding;
        padding-bottom: $section-padding;

        @include mq(lg) {
            padding-top: $section-padding * 1.5;
            padding-bottom: $section-padding * 1.5;
        }
    }

    &__title {
        margin-bottom: 1.5rem;
    }

    &__cta {
        position: relative;
        z-index: 1;
        text-align: center;
        margin: 0;
        padding-top: $section-padding;
    }

    &__crosslink {
        display: block;
        padding: 2rem;

        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        left: 0;
        transform: translateY(50%);

        font-size: 0.875rem;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.25em;
        color: $gray-lightest;
        background-color: var(--color-brand-primary);
        outline-color: var(--color-brand-secondary) !important;
        transition: background-color 0.15s linear;

        .icon {
            transform: translateY(-10%);
        }

        @include hover-focus {
            color: inherit;
            background-color: var(--color-brand-primary-offset);
        }

        @include mq(md) {
            font-size: 1rem;
            padding: 2rem 5rem;
            left: auto;
        }

        @include mq(lg) {
            padding: 3rem 7rem;
        }
    }

    // Color Schemes
    &--primary::before {
        background: var(--gradient-brand-primary);
    }
    &--secondary::before {
        background: var(--gradient-brand-secondary);
    }
    &--tertiary::before {
        background: var(--gradient-brand-tertiary);
    }
    &--muted {
        background: var(--color-scale-25);
    }
    &--dark {
        color: var(--color-scale-25);
        background-color: var(--color-scale-75);

        @at-root :root[data-theme='dark'] & {
            background-color: var(--color-scale-0);
            color: var(--color-scale-100);
        }
    }
    &--inverted {
        color: var(--color-scale-0);
        background-color: var(--color-scale-100);

        .capitals {
            font-weight: 700;
        }
    }
    &--centered {
        text-align: center;

        .lead {
            margin-left: auto;
            margin-right: auto;
        }
    }

    // specific versions
    &--services {
        #{$p} {
            &__inner {
                //padding-block: 0;

                @include mq(lg) {
                    display: grid;
                    grid-template-columns: 1.125fr 1fr;
                    grid-template-rows: 1fr;
                    align-items: start;
                    gap: 2rem;
                }
            }

            &__main {
                @include mq(lg) {
                    position: sticky;
                    top: 2rem;
                }
            }

            &__title {
                @include mq(lg) {
                    font-size: 2.25rem;
                    text-wrap: balance;
                }
            }

            &__side {
            }
        }

        .list-service-items {
            @include mq-down(lg) {
                margin-block-start: 2rem;
            }

            & > * + * {
                margin-block-start: 1rem;
            }
        }
    }

    &--featured-testimonial {
        @extend .section--centered;
        @extend .section--muted;

        @include mq(md) {
            text-align: center;
        }

        .logobar {
            margin-bottom: $section-padding;

            @include mq(lg) {
                margin-bottom: $section-padding * 1.5;
            }
        }

        .testimonial {
            margin-bottom: var(--section-spacing);
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &--solutions {
        .section__title,
        .section__intro {
            max-width: 55rem;
            margin-inline: auto;
        }

        .grid {
            gap: calc(#{$device-card-image-overlap} + 1rem);
            padding-block-start: $device-card-image-overlap;

            @include mq(lg) {
                gap: 1rem;
            }
        }

        .solution-item {
            p {
                text-wrap: balance;
            }

            .device-card {
                @include mq(lg) {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .device-card__text {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        p {
                            margin-block-start: auto;
                        }

                        .btn {
                            align-self: start;
                        }
                    }
                }
            }
        }
    }

    &--customer-experience {
        @extend .section--centered;
        @extend .section--dark;

        .section__title {
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
        }

        .form {
            max-width: 60ch;
            margin-inline: auto;
        }
    }

    &--contact {
        .container {
            max-width: 840px;
        }
    }

    &--about {
        #{$p} {
            &__inner {
                //padding-block-start: 0;
            }

            &__title {
                text-wrap: balance;
            }
        }

        .list-team-members {
            margin-block: 4rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;
        }

        .teammember {
            &__avatar {
                inset-block-start: 0;
            }

            &__content {
                align-self: center;
            }
        }

        .list-things {
            .thing {
                height: 100%;
            }
        }
    }

    &--double-cta {
        .thing {
            --thing-bg: hsl(0 0% 0% / 0.03);
            border: none;
            background-color: var(--thing-bg);
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 1rem;

            &__title {
                margin-block-end: 0;
            }

            .btn {
                margin-block-start: auto;
            }

            @include mq(md) {
                padding: 2rem;
                height: 100%;
            }

            @include mq(xl) {
                padding: 3rem;
                border-radius: $border-radius;
            }
        }
    }

    &--next-steps {
        //background-color: var(--color-scale-25);
        background-image: linear-gradient(to bottom, #fafa);

        #{$p} {
            &__next-steps {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin-block-start: 3rem;

                @include mq(md) {
                    flex-direction: row;
                }
            }
        }

        .next-step {
            text-align: start;
            text-wrap: wrap;
            padding: 1rem;
            //background-color: #fff;
            //box-shadow: $box-shadow-card;
            border: 2px solid hsla(0, 0%, 0%, 0.075);
            border-radius: $border-radius;

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            @include mq(md) {
                flex: 1;
                padding: 1.5rem;
                display: flex;
                flex-direction: column;

                p {
                    flex: 1;
                }
            }

            @include mq(lg) {
                padding: 2rem;
            }
        }
    }

    &--follow-us {
        .follow-us-members {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2rem;
            margin-block-start: 3rem;

            .teammember {
                grid-template-columns: 1fr;
                grid-template-rows: var(--teammember-avatar-size) 1fr;
                justify-content: center;
                justify-items: center;
                text-align: center;

                .btn {
                    margin-block-start: 1rem;
                }
            }
        }
    }

    &--digital-commerce {
        #{$p} {
            &__inner {
                @include mq(lg) {
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    gap: var(--section-spacing);
                    align-items: center;
                }
            }

            &__main {
            }

            &__side {
                margin-block-start: var(--section-spacing);

                @include mq(lg) {
                    margin-block-start: 0;
                }

                .pill-list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1em;

                    @include mq(lg) {
                        justify-content: center;
                    }
                }

                .pill {
                    transition-property: border-color, background-color, color,
                        box-shadow, transform;
                    transition-duration: 750ms;
                    transition-timing-function: $animation-curve-linear-out-slow-in;

                    &:where(.is-highlighted, :hover) {
                        background-color: var(--color-brand-primary);
                        border-color: transparent;
                        color: var(--color-scale-25);
                        transform: translateY(-0.25em);
                        box-shadow: $box-shadow-elevated;
                    }
                }
            }
        }
    }

    &--member-index {
        background-color: hsla(0, 0%, 0%, 0.025);

        .teamindex {
            &__list {
                text-align: start;
                display: flex;
                flex-direction: column;
                gap: var(--section-spacing);
                margin-block-start: var(--section-spacing);

                @include mq(lg) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: var(--section-spacing);
                }
            }

            &__item {
                margin-block-end: 0;
                border: 1px solid hsla(0, 0%, 0%, 0.05);
                padding: 1.5rem;

                .teammember {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}
