// Main Typography Styles

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 1.3;
}

h1,
.h1 {
    font-size: 2.625rem; //42px
    line-height: 1.2;

    @include mq(md) {
        font-size: 5.125rem; // 82px
    }
}

h2,
.h2 {
    font-size: 2.25rem; //36px
    @include mq(md) {
        font-size: 3rem; // 48px
    }
}

h3,
.h3 {
    font-size: 1.5rem; //24px
    margin-bottom: 1rem;

    @include mq(md) {
        font-size: 2rem; // 32px
    }
}

// Lead Paragraph

.lead {
    font-size: 1.375rem; //22px
    line-height: 1.5;
    max-width: 35em;
}

// Capitals Label

.capitals {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.25em;
    text-wrap: balance;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5 !important;

    &--num {
        display: inline-block;
        vertical-align: middle;
        font-variant-numeric: tabular-nums;
        user-select: none;
        white-space: nowrap;
        width: 3.5ch;
    }

    @include mq(md) {
        font-size: 0.875rem;

        &--num {
            margin-left: -3.5ch;
        }
    }
}

// Hyphenation

.hyphenate {
    @include hyphenate;
}

// Truncation

.truncate {
    @include truncate;
}

// Lists

.ul,
.ol {
    list-style-position: outside;
    line-height: 1.5;
    padding-left: 1.125rem;

    li {
        padding: 0.5em;

        &::marker {
            color: var(--color-brand-primary);
            font-weight: 700;
        }
    }
}

.ul {
    list-style-type: disc;
}

.ol {
    list-style-type: decimal;
}
