.accordion {
    text-align: left;

    @include mq(lg) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem 4rem;
    }

    &__target {
        position: relative;
        border-bottom: 2px solid var(--color-scale-25);
    }
    &__header {
        @include button-reset;
        display: flex;
        width: 100%;
        font-size: 1.25rem;
        padding: 1rem 0;
        text-align: left;
        position: relative;
    }
    &__panel {
        max-height: 75vh;
        overflow: hidden;
        margin-bottom: 0;

        @at-root :root[data-theme='dark'] & {
            margin-block-start: 1.5em;
        }

        p {
            margin: 0;
        }
    }
    &__indicator {
        display: inline-block;
        flex: none;
        transform: rotate(0);
        transition: transform ease 0.2s;
        color: var(--color-brand-primary);
        position: relative;
        top: -2px;
    }
}

.accordion--initialized {
    .accordion__panel {
        transition: max-height 0.2s ease-in-out;
    }
}

.accordion__item--closed {
    .accordion__panel {
        max-height: 0 !important;
    }
    .accordion__indicator {
        transform: rotate(-90deg);
    }
}
