.service-detail {
    --service-detail-padding: 1rem;
    text-align: start;
    box-shadow: $box-shadow-card;
    border-radius: $border-radius;
    text-wrap: balance;
    margin-block: 0;
    transition: margin-block 350ms $animation-curve-default;
    background-color: var(--color-scale-0);

    @at-root :root[data-theme='dark'] & {
        border: 1px solid var(--color-scale-25);
    }

    @include mq(md) {
        --service-detail-padding: 1.75rem;
    }

    @include mq(lg) {
        --service-detail-padding: 2.5rem;
    }

    &[open] {
        --_new-margin: max(var(--service-detail-padding) * 0.75, 1rem);
        // this inbetween custom prop is necessary, because our current old version of sass will attempt to perform an actual calculation with the var()*.75, which it cannot do, so it throws an error. But, saving to a dedicated custom prop will prevent it from doing so.
        margin-block: var(--_new-margin);
    }

    &__summary {
        list-style: none; // removes the marker
        padding: var(--service-detail-padding);

        &::-webkit-details-marker {
            display: none;
        }

        &::marker {
            display: none;
        }

        .btn--link {
            //grid-column: 1/-1;
            justify-self: start;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__icon {
        svg {
            display: block;
            width: 2.5rem;
            height: auto;
            margin-bottom: calc(var(--service-detail-padding) / 2);
        }
    }

    &__heading {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.25em;
    }

    &__content {
        padding: var(--service-detail-padding);
        padding-block-start: 0;
        --flexible-list-gap: max(var(--service-detail-padding) * 0.75, 1rem);

        .thing {
            padding: 0;
            border: 0;

            &__title {
                font-size: 1rem;
                font-weight: 600;
            }

            &__description {
                font-size: 0.85rem;
            }
        }
    }
}
