// Custom Checkbox Field

$checkbox-indicator-size: 1.5em;
$checkbox-indicator-color: $state-info;

.checkbox {
    @extend %field;

    &__label {
        display: block;
        position: relative;
        cursor: pointer;
        padding-left: $checkbox-indicator-size * 1.5;
        margin: 0;
        min-height: $checkbox-indicator-size;
        line-height: $checkbox-indicator-size;

        a:hover,
        a:focus {
            text-decoration: underline;
        }
    }

    &__text {
        @include hyphenate;
        line-height: 1.5rem;
    }

    &__input {
        @include sr-only;
    }

    &__indicator {
        display: inline-block;
        width: $checkbox-indicator-size;
        height: $checkbox-indicator-size;
        position: absolute;
        left: 0;
        border: 1px solid $gray;
        border-radius: 0.125rem;

        // custom checkbox element
        &::before {
            content: '';
            position: absolute;
            font-size: $checkbox-indicator-size;
            left: 0.35em;
            top: 0.15em;
            width: 0.3em;
            height: 0.55em;
            transform: rotate(40deg);
            border: solid $body-bg;
            border-width: 0 0.125em 0.125em 0;

            visibility: hidden;
            opacity: 0;
        }

        // invalid state
        .is-invalid & {
            border-color: $state-error;
        }

        // focus state
        .checkbox__input:hover ~ &,
        .checkbox__input:focus ~ & {
            border-color: $gray;
            box-shadow: 0 0 0 1px $field-border-color-focus;
        }

        // checked state
        .checkbox__input:checked ~ & {
            border-color: $brand-primary;
            box-shadow: 0 0 0 1px $field-border-color-focus;
            background-color: $brand-primary;

            &::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__error {
        @extend %field__error;
        position: static;
    }
}
