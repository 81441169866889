.commanddirectory {
    &__prompt {
        max-width: 640px;
        margin: 0 auto 2rem;
        position: relative;

        &__prefix {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
            user-select: none;
            font-family: $font-family-base;
            font-weight: 700;
            color: $brand-tack;
        }
    }
    &__input {
        display: block;
        width: 100%;
        padding: 1rem;
        padding-left: 4rem;
        border-radius: 0.25rem;
        background-color: var(--color-scale-0);
        box-shadow: $box-shadow-elevated;
        border: 1px solid var(--color-scale-75);

        &:focus {
            border-color: var(--color-brand-primary);
            outline: 0;
        }
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 2rem;
    }
    &__item {
        display: flex;
        flex-direction: column;
    }
    &__empty {
        max-width: 640px;
        margin: 0 auto;
    }

    &__cmd {
        flex: 1 0 auto;
        border-radius: 0.25rem;
        border: 2px solid var(--color-scale-25);
        padding: 1rem;

        &__title {
            font-size: 1.25rem;
            margin-bottom: 0.25rem;
        }
        &__example {
            margin-bottom: 1rem !important;
        }
        &__desc {
            margin-bottom: 0 !important;
        }
    }
}
