.testimonial {
    --testimonial-font-size: 1rem;
    --testimonial-avatar-size: 45px;
    --testimonial-padding: 1rem;

    @include mq(md) {
        --testimonial-font-size: 1.25rem;
        --testimonial-avatar-size: 60px;
        --testimonial-padding: 1.5rem;
    }

    @include mq(lg) {
        --testimonial-padding: 2.5rem;
    }

    font-size: var(--testimonial-font-size);
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    padding: var(--testimonial-padding);
    margin: 0;
    background-color: var(--color-scale-0);
    //border: 1px solid var(--color-scale-25);
    border-radius: $border-radius;
    box-shadow: $box-shadow-card;
    text-align: left;

    @at-root :root[data-theme='dark'] & {
        background-color: var(--color-scale-25);
    }

    &__logo {
        margin-block-end: calc(var(--testimonial-padding) / 2);

        img {
            display: block;
            max-height: 50px;
        }
    }

    &__quote {
        flex: 1 0 auto;
        font-family: $font-family-system;
        font-size: 1em;
        font-weight: 300;
        line-height: 1.65;
        max-width: 65ch;
        quotes: '„' '“' '‚' '‘';
        margin-bottom: calc(var(--testimonial-padding) / 2);
        hanging-punctuation: first;

        b {
            font-weight: 700;
        }

        p::before {
            content: open-quote;
        }
        p::after {
            content: close-quote;
        }
    }
    &__caption {
        position: relative;
        //padding-left: calc(var(--testimonial-avatar-size) * 0.75);
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 1rem;

        p {
            margin-block-end: 0;
        }
    }
    &__avatar {
        display: block;
        border-radius: 50%;
        width: var(--testimonial-avatar-size);
        height: var(--testimonial-avatar-size);
        //background-color: var(--color-scale-0);
        //border: 5px solid var(--color-scale-0);
        object-fit: cover;
        //box-shadow: 0 2px 14px rgba(0, 0, 0, 0.15);
        //position: absolute;
        //left: 0;
        //transform: translate(-50%, -10%);
        grid-column: 1;
        grid-row: 1/-1;
    }
    &__author {
        display: block;
        position: relative;
        z-index: 1;
        margin-bottom: 0.25rem;
        font-style: inherit;
        font-size: 1em;
        font-weight: 700;
        align-self: end;

        > a {
            font-size: 1em;
        }
    }
    &__position {
        display: block;
        font-size: 0.75em;
        line-height: 1.5;
        color: var(--color-scale-50);
    }
}

.testimonial-slide {
    max-width: 800px;
    padding: 0 2rem 2rem;
    margin: 0 auto 1rem;
}

$testimonial-animation-duration: 350ms;

.testimonials-stack {
    max-width: 640px;
    margin-inline: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-block-start: 1rem;
    align-items: center;

    @include mq(md) {
        margin-block-start: 2rem;
    }

    &__item {
        grid-column: 1/-1;
        grid-row: 1/-1;
        opacity: 0;
        z-index: 0;
        transform: translateY(1rem);
        transition-property: opacity, transform;
        transition-duration: $testimonial-animation-duration;
        transition-timing-function: $animation-curve-default;

        &.is-active {
            opacity: 1;
            z-index: 1;
            transform: translateY(0);
        }
    }

    .testimonial {
        max-width: 100%;
    }
}

.testimonials-logos {
    --logo-width: 150px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--logo-width), 1fr));
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 800px;
    margin-inline: auto;
    margin-block-start: 1rem;

    @include mq(lg) {
        display: flex;
        flex-wrap: wrap;
        margin-block-start: 2rem;
    }

    &__item {
        --testimonial-logo-radius: 1em;
        --_testimonial-bg: hsl(0 0% 0% / 0.025);
        background-color: var(--_testimonial-bg);
        padding: 0.35em;
        border-radius: var(--testimonial-logo-radius);

        & > button {
            display: inline-block;
            background-color: transparent;
            border: 0;
            padding: 1em 2em;
            border-radius: calc(var(--testimonial-logo-radius) * 0.75);
            appearance: none;
            transition-property: box-shadow, background-color;
            transition-timing-function: $animation-curve-default;
            transition-duration: $testimonial-animation-duration;
            width: 100%;
            text-align: center;

            @at-root :root[data-theme='dark'] & {
                border: 1px solid var(--color-scale-25);
            }

            > img {
                display: block;
                width: 150px;
                height: 50px;
                object-fit: contain;
                transition: inherit;
                transition-property: filter;
                filter: grayscale(1);
                margin-inline: auto;
            }
        }

        &.is-active {
            & > button {
                background-color: var(--color-scale-0);
                box-shadow: $box-shadow-card;
                filter: none;

                @at-root :root[data-theme='dark'] & {
                    border-color: var(--color-scale-50);
                }

                > img {
                    filter: none;
                }
            }
        }

        &:where(:hover, :focus-within) {
            & > button > img {
                filter: grayscale(0);
            }
        }
    }
}
