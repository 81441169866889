// Icon Component
.icon {
    display: inline-block;
    font-size: 1.5em;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    fill: currentColor;
}

.large-icon-container {
    font-size: 4rem;
    margin-bottom: 0.5rem;
}

// ==========================
// Animated Pure CSS Menu Burger Icon
// ==========================

.menuicon {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
    transform: rotate(0deg);
    transition: transform 0.3s $animation-curve-default;
    cursor: pointer;
    margin: 0 auto;

    &__bar {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        width: 100%;
        background-color: currentColor;
        transition: transform 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 50%;
            transform: rotate(0deg) translateX(4px);
        }
        &:nth-child(4) {
            top: 100%;
        }
    }

    // Active State: Transform to "X"
    .nav-open & {
        top: 3px;
        transform: rotate(-180deg);

        .menuicon__bar {
            &:nth-child(1),
            &:nth-child(4) {
                width: 0;
                top: 50%;
                left: 50%;
            }
            &:nth-child(2) {
                transform: rotate(45deg) translateX(0);
            }
            &:nth-child(3) {
                transform: rotate(-45deg) translateX(0);
            }
        }
    }
}
