.container-download {
    flex: 1 1 37em;
    min-width: 350px;
    max-width: 600px;
    padding: 0 3rem 0 3rem;
}

.download {
    max-width: 750px;
    margin: 0 auto;
    background-color: var(--color-scale-0);
    box-shadow: $box-shadow-elevated;
    border: 1px solid $gray-light;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
    }

    &__content {
        padding: 2rem 2rem 1rem;
    }
    &__form {
        flex-basis: 100%;
        position: relative;
        padding: 0 2rem 2rem;
    }

    &__content {
        text-align: left;
        border-bottom: 1px solid $gray-light;
    }

    &__title {
        padding-top: 1rem;

        .icon {
            margin-right: 0.25em;
            font-size: 1em;
            transform: translateY(-0.125em);
        }
    }

    .spinner,
    .checkmark {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
    }

    .checkmark {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 3rem;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: $brand-secondary;
        box-shadow: $box-shadow;
        color: #fff;
        transition: all 0.15s $animation-curve-default;
        transform: translate(-50%, -50%) scale(2) rotate(18deg);
        opacity: 0;
        pointer-events: none;
    }

    .field {
        padding-top: 0;
        padding-bottom: 2rem;
    }

    @include mq(md) {
        // &__content,
        // &__form {
        //     max-width: 50%;
        //     flex-basis: 50%;
        // }

        &__content {
            border-bottom: 0;
            border-right: 1px solid $gray-light;

            .rich-text {
                font-size: 1rem;
            }
        }
    }
}

// .signup--loading {
//     .form {
//         pointer-events: none;
//         opacity: 0.5;
//     }
//     .spinner {
//         display: block;
//     }
// }

// .signup--success {
//     .form {
//         pointer-events: none;
//         opacity: 0.5;
//     }
//     .checkmark {
//         opacity: 1;
//         transform: translate(-50%, -50%) scale(1) rotate(0);
//     }
// }
