.glossary {
    max-width: 46rem;
    margin: 0 auto;

    &__search {
        position: relative;
        margin-bottom: 2rem;
    }
    &__searchlabel {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        user-select: none;
        font-family: $font-family-base;
        font-weight: 700;
    }
    &__input {
        display: block;
        width: 100%;
        padding: 1rem;
        padding-left: 5rem;
        border-radius: 0.25rem;
        background-color: var(--color-scale-0);
        box-shadow: $box-shadow-elevated;
        border: 1px solid var(--color-scale-75);

        &:focus {
            border-color: var(--color-brand-primary);
            outline: 0;
        }
    }
    &__item {
        margin-bottom: 2rem;
        scroll-margin-top: 2rem;
    }
    &__header {
        display: block;
        font-size: 1.5rem;

        .is-highlighted & {
            animation: highlight 1.5s;
        }
    }
    &__term {
        @include hyphenate;
        line-height: 1.3;
    }
    &__link {
        display: inline-block;
        margin-right: 0.25rem;
    }

    @include mq(md) {
        &__item {
            padding-left: 3rem;
        }
        &__header {
            margin-left: -3rem;
        }
    }
}
