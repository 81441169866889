.logo {
    position: relative;
    top: 4px; // optical alignment; account for cursor height

    &__type {
        fill: var(--color-scale-100);
    }

    &__cursor {
        color: var(--color-brand-secondary);
        fill: currentColor;

        .header a:focus &,
        .header a:hover & {
            animation: cursor-blink 3s step-end infinite;
        }
    }
}

.logo--typing {
    .logo__mask {
        transform: scaleX(0.13846153846);
        animation: logo-typing 0.5s 1s step-end forwards;
    }
    .logo__cursorgroup {
        transform: translateX(-75%);
        animation: cursor-move 0.5s 1s step-end forwards;
    }
    .logo__cursor {
        animation: cursor-blink 3s 1.5s step-end 2;
    }
}

@keyframes logo-typing {
    0% {
        transform: scaleX(0.13846153846);
    }
    16.666% {
        transform: scaleX(0.303846154);
    }
    33.333% {
        transform: scaleX(0.480769231);
    }
    50% {
        transform: scaleX(0.551923077);
    }
    66.666% {
        transform: scaleX(0.703846154);
    }
    83.333% {
        transform: scaleX(0.823076923);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes cursor-blink {
    0% {
        color: transparent;
    }
    16.666% {
        color: var(--color-brand-primary);
    }
    33.333% {
        color: transparent;
    }
    50% {
        color: var(--color-brand-tertiary);
    }
    66.666% {
        color: transparent;
    }
    83.333% {
        color: var(--color-brand-secondary);
    }
}

@keyframes cursor-move {
    0% {
        transform: translateX(-75%);
    }
    16.666% {
        transform: translateX(-61%);
    }
    33.333% {
        transform: translateX(-44%);
    }
    50% {
        transform: translateX(-39.5%);
    }
    66.666% {
        transform: translateX(-26%);
    }
    83.333% {
        transform: translateX(-15%);
    }
    100% {
        transform: translateX(0);
    }
}
