.langswitch {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    &__link {
        display: block;
        padding: 0.5rem;
        text-transform: uppercase;
        color: inherit;
        letter-spacing: 1px;

        &--active {
            font-weight: bold;
        }
    }
    &__divider {
        display: block;
        width: 0px;
        height: 1.25em;
        border-right: 1px solid currentColor;
        opacity: 0.35;
        position: relative;
        top: -0.1em;
    }
}
