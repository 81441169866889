$project-grid-gap: 2rem;

.project {
    $p: &;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;

    @include mq(lg) {
        grid-gap: $project-grid-gap;
        grid-template-columns: 4fr 3fr;
    }

    @supports (display: grid) {
        &__image,
        &__content {
            flex: none !important;
            max-width: none !important;
            padding-left: 0 !important;
        }
    }

    &__image {
        position: relative;
        padding: $project-grid-gap 0;

        @include mq(lg) {
            flex-basis: 50%;
            max-width: 50%;
        }

        > img {
            width: 100%;
            height: auto;
            position: relative;
            z-index: 1;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -10%;
            top: 0;
            bottom: 0;
            right: 50%;
            background-color: var(--color-scale-25);

            .project--primary & {
                background-image: var(--gradient-brand-primary);
            }
            .project--secondary & {
                background-image: var(--gradient-brand-secondary);
            }
            .project--tertiary & {
                background-image: var(--gradient-brand-tertiary);
            }
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        padding: $project-grid-gap 0;

        @include mq(lg) {
            flex-basis: 50%;
            max-width: 50%;
            padding-left: $project-grid-gap;
        }
    }

    &__logo {
        height: 1.5rem;
        margin-block-end: $project-grid-gap/2;

        img {
            display: block;
            height: 100%;
            width: auto;
            max-width: 100%;
        }
    }

    &__title {
        margin-bottom: 0.5rem;
        text-wrap: balance;
    }
    &__subtitle {
        margin-bottom: 2rem;
    }
    &__intro {
        max-width: 60ch;
    }
    &__link {
        @include stretched-link;
        display: flex;
        align-items: center;
        font-weight: 600;
        line-height: 1;
        align-self: start;

        .icon {
            transition: transform 0.2s ease;
        }

        @include hover-focus {
            text-decoration: underline;

            .icon {
                transform: translateX(50%);
            }
        }
    }
    &__external-link {
        display: flex;
        align-items: center;
        width: 4rem;
        height: 4rem;
        margin-top: 1rem;
        justify-content: center;
        text-align: center;
        border-radius: 50%;
        background-color: $gray-darkest;
        color: $gray-lightest;
        transition: background-color 0.2s ease;

        @include hover-focus {
            color: $gray-lightest;
            background-color: var(--color-brand-primary);

            .icon {
                transform: none;
            }
        }

        @include mq(lg) {
            position: absolute;
            left: 50%;
            top: 1.5rem;
            transform: translate(-50%, -50%);
            z-index: 5;
        }
    }

    &--vertical {
        --project-padding: 1rem;
        display: flex;
        flex-direction: column-reverse;
        border: 1px solid var(--color-scale-25);
        gap: 0;

        @include mq(lg) {
            --project-padding: 2rem;
        }

        #{$p} {
            &__logo {
                margin-block-end: calc(var(--project-padding) / 2);
            }

            &__content {
                margin: var(--project-padding);
                padding: 0;
                flex: 1 !important;
            }

            &__subtitle {
                margin-bottom: var(--project-padding);
            }

            &__intro {
                flex: 1;

                p {
                    margin-block-end: 0;
                }
            }

            &__link {
                margin-block-start: calc(var(--project-padding) / 2);
            }

            &__image {
                padding: var(--project-padding) !important;
                padding-block-start: 0 !important;

                @include mq(md) {
                    height: 250px;
                }

                @include mq(xl) {
                    height: 350px;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &::before {
                    @debug &;
                    inset: var(--project-padding);
                    inset-block-start: 20%;
                }
            }
        }
    }
}
