// Header Component

.header {
    position: relative;

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &__brand {
        position: relative;
        z-index: z('nav') + 1;
    }

    &__navigation {
        margin-inline-start: auto;
    }

    @include mq(md) {
        border-top: 5px solid var(--color-scale-25);
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.banner + .header {
    border-top: 0;
}
