// Keyframe Animation Definitions
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0s !important;
        transition-duration: 0s !important;
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(32px);
    }
    to {
        opacity: 1;
        transform: translate(0px);
    }
}

@keyframes pulsebeat {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes flicker {
    0%,
    19.999%,
    22%,
    62.999%,
    64%,
    64.999%,
    72%,
    100% {
        opacity: 1;
    }
    20%,
    21.999%,
    63%,
    63.999%,
    65%,
    71.999% {
        opacity: 0.33;
    }
}

@keyframes highlight {
    from {
        background-color: rgba($brand-secondary, 1);
    }

    to {
        background-color: rgba($brand-secondary, 0);
    }
}
