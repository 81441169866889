.page {
    &__header {
        padding-top: $spacing-y;
        padding-bottom: $spacing-y;

        @media (min-height: 750px) {
            padding-top: $spacing-y * 2;
            padding-bottom: $spacing-y * 2;
        }
    }
    &__content {
        padding-top: $spacing-y;
        //padding-bottom: $spacing-y; not necessary, because there'll always be a page section with a padding
    }
    &__title {
        @extend h1;
        margin-bottom: 1rem;

        font-size: 2.625rem; //42px

        @include mq(md) {
            font-size: 4rem; // 82px
        }

        @include mq-down(lg) {
            @include hyphenate;
        }
    }
    &__meta,
    &__intro {
        margin: 0;
    }
    &__sharing {
        margin-bottom: 2rem;
    }
}

.page--article,
.page--default {
    @include mq(md) {
        .page__header {
            text-align: center;
        }
        .page__meta,
        .page__intro {
            margin: 0 auto;
        }
    }
}

.page--article {
    .page__toc {
        $page-toc-padding: 1.25rem;
        background-color: $light;
        font-size: 1rem;
        max-width: $streamfield-paragraphs-width;
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;

        summary {
            padding: $page-toc-padding;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.25rem;

            &:hover {
                cursor: pointer;
            }

            &::after {
                content: '+';
                line-height: 1;
                // visually align it correctly to the center
                transform: translateY(-0.1em);
                font-size: 1.5em;
            }
        }

        .toc {
            $toc-item-spacing: 0.5rem;
            margin-top: 0;
            padding: $page-toc-padding;
            padding-top: 0;

            a {
                text-decoration: underline;
            }

            ol {
                counter-reset: heading-level;

                li {
                    line-height: 1.5;

                    &::before {
                        counter-increment: heading-level;
                        content: counters(heading-level, '.') '.';
                    }

                    & + * {
                        margin-top: $toc-item-spacing;
                    }
                }

                ol {
                    padding-left: 1rem;
                    margin-top: $toc-item-spacing;
                }
            }
        }

        &[open] {
            summary {
                &::after {
                    content: '-';
                }
            }
        }

        @include mq(md) {
        }
    }
    .page__meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .page__date,
    .page__author {
        margin-bottom: 1rem;
    }
    @include mq-down(md) {
        .page__header {
            padding-bottom: 1rem;
        }
    }
    @include mq(md) {
        .page__meta {
            align-items: center;
            flex-direction: row;
        }
        .page__date {
            margin: 0 2rem 0 0;
        }
        .page__author {
            margin: 0;
        }
    }
}

.page--tack {
    .streamfield .block-image {
        overflow: visible;
        max-width: 44rem;
        margin-bottom: 0;

        @include mq(md) {
            > figure {
                margin-left: -1.5rem;
            }
        }
    }
}

.page--blog-index {
    .page {
        &__meta {
            margin-top: $spacing-y;
        }
    }
}

.page--pb0 {
    .page__content {
        padding-bottom: 0;
    }
}

.page--contact {
    .page {
        &__header {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            @include mq(md) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
            }

            &-text {
            }

            &-arguments {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-block-start: 3rem;

                li {
                    display: flex;
                    align-items: center;
                    text-wrap: balance;
                    gap: 0.5em;

                    .icon {
                        font-size: 2rem;
                        color: var(--color-brand-primary);

                        & > svg {
                            display: block;
                        }
                    }
                }
            }

            &-actions {
                margin-block-start: 2rem;
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
            }

            &-form {
                box-shadow: $box-shadow-card;
                background-color: var(--color-scale-0);
                border-radius: $border-radius;
                position: relative;
                padding: 2rem;

                .spinner {
                    position: absolute;
                    inset-inline-start: 50%;
                    inset-block-start: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 0;
                }

                iframe {
                    position: relative;
                    z-index: 1;
                }
            }

            &-contactperson {
                margin-block-start: 2rem;
            }
        }

        &__content {
            padding-bottom: 0;
        }
    }

    .section--logobar .section__inner {
        //padding-block: 0;
    }
}

.page--contact-success {
    .page {
        &__header {
            text-align: center;
            display: block;
        }

        &__title {
            @extend .alert;
            @extend .alert--success;
            border-width: 2px;
            margin-block-end: 0;
            line-height: 1.3;
            font-size: 1.5rem;
        }
    }

    .contact-links {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;
        justify-content: center;
        gap: 1em;
    }
}

.page--solution-detail {
    .page__header {
        text-wrap: balance;

        @include mq(md) {
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            grid-template-rows: auto auto auto;
        }
    }

    .page__image {
        margin-block-start: 2rem;

        @include mq(md) {
            margin-block: 0;
            grid-column: 2/-1;
            grid-row: 1 / -1;
            align-self: center;
        }

        img {
            display: block;
            height: auto;
        }
    }

    .page__content {
        padding-block-end: 0;

        .section:first-child {
            @extend .section--muted;

            .thing {
                border-color: hsla(0, 0%, 0%, 0.1);
            }
        }
    }

    .streamfield {
        h2 {
            @extend .section__title;

            @include mq(md) {
                font-size: 2.5rem;
            }
        }
    }
}
