%richtext {
    h2,
    h3,
    h4 {
        @extend .h3;
        line-height: 1.45;
        hyphens: auto;

        &:not(:first-child) {
            margin-top: 3rem;
        }
    }

    a:not(.btn) {
        @include hyphenate;
        $base-color: #{var(--color-brand-secondary)};
        font-weight: 600;
        color: inherit;
        outline-color: var(--link-color-scheme, #{$base-color});
        text-decoration: none;
        background-image: linear-gradient(
            to top,
            var(--link-color-scheme, #{$base-color}) 40%,
            transparent 40%
        );

        @include hover-focus {
            background-image: linear-gradient(
                to top,
                var(--link-color-scheme, #{$base-color}) 0%,
                var(--link-color-scheme, #{$base-color}) 100%
            );
        }
    }

    p {
        margin-bottom: $richtext-flow-spacing;
    }

    strong,
    b {
        font-weight: 600;
    }

    em,
    i {
        font-style: italic;
    }

    ul,
    ol {
        padding-left: 1.5rem;
        margin-bottom: $richtext-flow-spacing;
        list-style-position: outside;

        li {
            padding: ($richtext-flow-spacing/6) 0;
            padding-left: 0.5rem;

            &::marker {
                color: var(--color-brand-primary);
            }
        }
    }

    ul {
        list-style-type: none;

        li {
            position: relative;
            &::before {
                content: '';
                display: block;
                width: 0.75em;
                height: 0.25em;
                background-color: var(--color-brand-primary);
                position: absolute;
                top: 0.85em;
                left: -1.5rem;
            }
        }
    }

    ol {
        list-style-type: decimal;
    }

    table {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    /* Inline code */
    :not(pre) code {
        display: inline-block;
        font-family: $font-family-monospace;
        background-color: rgba(#6b5aed, 0.15);
        border: 1px solid lighten(#6b5aed, 10%);
        color: var(--color-brand-primary-offset);
        border-radius: 0.25em;
        padding: 1px 4px;
        margin: 0 2px;
        font-size: 75%;
    }

    p:empty,
    h2:empty,
    h3:empty,
    ul:empty,
    ol:empty {
        display: none;
    }
}

// legacy: previous wagtail versions used this per default
.rich-text {
    @extend %richtext;
}
