// Table Component

.table {
    width: 100%;
    max-width: 100%;
    margin: $spacing-y 0;
    font-size: 0.875rem;
    border: 1px solid var(--color-scale-25);

    tbody {
        border: 1px solid var(--color-scale-25);
    }

    td,
    th {
        @include hyphenate;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid var(--color-scale-25);
        border-left: 1px solid var(--color-scale-25);
        vertical-align: middle;
    }

    td {
        font-variant-numeric: tabular-nums;
    }

    th {
        font-weight: 700;
        text-transform: uppercase;
        color: var(--color-scale-75);
    }

    thead th {
        padding-bottom: 0.5rem;
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.03);
    }

    code {
        white-space: nowrap;
    }
}

.table--responsive {
    @media screen and (max-width: 640px) {
        border: 0;

        thead {
            display: none;
        }
        tbody {
            border: 0;
        }

        tr,
        td {
            display: block;
            text-align: left;
        }

        tr {
            border: 1px solid var(--color-scale-25);
            border-bottom: 0;
            + tr {
                margin-top: 2rem;
            }
        }

        th[scope='row'] {
            display: block;
            width: 100%;
            border-right: 1px solid var(--color-scale-25);
        }

        td {
            display: flex;
            border-left: 0;
        }

        td[label]::before {
            @include truncate;
            text-transform: uppercase;
            display: inline-block;
            content: attr(label);
            font-size: 0.875rem;
            font-weight: 700;
            flex: 0 0 45%;
            max-width: 45%;
        }
    }
}
