.sharing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        color: var(--color-scale-100);
        background-color: var(--color-scale-25);
        margin: 0 1rem;

        @include hover-focus {
            background-color: var(--color-brand-primary);
            color: $gray-lightest;
        }
    }
}
