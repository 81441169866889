// a simple, _very_ flexible list - it is done that way, because we don't really have, by intention, much control over orphans in CSS grid. This list gives the author maximum control over how her items are layed-out in the list. She can even determine what to do with orphans.
@mixin flexible-list() {
    // this is intended to be used with markup, where as inline-style, the number of preferred columns is set via --flexible-list-columns.
    --flexible-list-columns: 3; // default value; to be overridden via markup

    // variables for internal calculations
    --_percentage-of-max-columns: 0;
    // calculates how many columns are to be set, based on the percentage of max columns set. It it will attempt to set at least two columns, but will always use the smallest value - to approximate a rounding/floor functionality.
    --_columns: min(
        max(
            calc(
                (var(--flexible-list-columns) / 100) *
                    var(--_percentage-of-max-columns)
            ),
            2
        ),
        var(--flexible-list-columns)
    );
    --_gap: var(--flexible-list-gap, 1em);
    // the available width must take the gap into consideration, which again depends on the number of columns.
    --_avaiable-width: calc(100% - (var(--_gap) * (var(--_columns) - 1)));
    // finally, we have the actual width of a given item.
    --_item-width: calc(var(--_avaiable-width) / var(--_columns));

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    gap: var(--_gap);

    // in order to keep the calculation of columns in the .list, we need to set the columns manually here.
    @include mq-down('s') {
        --_columns: 1;
    }

    // per breakpoint, all we need to do is set how many columns we want - as a percentage of the maximum columns defined via --flexible-list-columns.
    @include mq('s') {
        --_percentage-of-max-columns: 25; // meaning, at this breakpoint, we want the number of columns to be 25% of the maximum number of columns.
        // if three are set in --flexible-list-columns, this would equate to 0.75, but we are falling back to at least two columns, so 2.
    }

    @include mq('md') {
        --_percentage-of-max-columns: 35;
    }

    @include mq('lg') {
        --_percentage-of-max-columns: 50;
    }

    @include mq('xl') {
        --_percentage-of-max-columns: 100;
    }

    & > * {
        // to make sure, long words won't break the grid.
        word-break: break-word;
        hyphens: auto;
        flex-basis: var(--_item-width);
    }

    // orphan utilities
    &.orphans {
        &--center {
            justify-content: center;
        }

        &--left {
            justify-content: start;
        }

        &--right {
            justify-content: end;
        }

        &--stretch {
            & > * {
                flex-grow: 1;
            }
        }

        &--stretch-last {
            & > *:last-child {
                flex: 1;
            }
        }
    }
}

.flexible-list {
    @include flexible-list();
}
