.projecttypes {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;

    &__item {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        text-align: left;
        padding: 1rem;

        @include mq(lg) {
            flex-basis: 33.33%;
            max-width: 33.33%;
        }

        .icon {
            color: var(--color-brand-primary);
            font-size: 5rem;
            margin-right: 1rem;
        }
    }
    &__title {
        font-weight: 600;
        letter-spacing: -0.03em;
        font-size: 1.25rem;
        margin: 0;
    }

    @supports (display: grid) {
        margin: 0;

        &__item {
            flex: none !important;
            max-width: none !important;
            padding: 0 !important;
        }
    }
}
