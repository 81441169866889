.teamindex {
    display: grid;
    gap: 2rem;

    &__item {
        display: block;
        scroll-margin-top: 2rem;
        margin-bottom: 2rem;
    }

    @include mq(lg) {
        grid-template-columns: 300px 1fr;
        gap: 4rem;

        &__item {
            margin-bottom: 4rem;
        }
    }
}

.teamnav {
    position: sticky;
    top: 2rem;
    color: #fff;
    background-color: $gray-darkest;
    border-radius: 15px;
    border: 1px solid rgba(#fff, 0.3);

    &__title {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        padding: 1.5rem;
        border-bottom: 1px solid var(--color-scale-50);
    }
    &__logo {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 10px;
        border: 3px solid var(--color-scale-0);
        margin-right: 1rem;
    }
    &__list {
        padding: 2rem 0;
    }
    &__link {
        display: flex;
        align-items: center;
        padding: 0.5rem 1.5rem;
        font-weight: 700;
        color: #fff;
        line-height: 1;

        @include hover-focus {
            color: #fff;
            background-color: var(--color-brand-primary);
        }
    }
    &__label {
        display: block;
        padding-top: 0.125rem;
        opacity: 0.85;
    }
    &__avatar {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        background-color: var(--color-scale-25);
        margin-right: 1rem;
        margin-left: 0.75rem;
        position: relative;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    &__badge {
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: var(--color-brand-secondary);
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 25%);
        border: 1px solid var(--color-scale-100);
    }
}

.teammember {
    --teammember-avatar-size: 3.5rem;
    @include mq(md) {
        --teammember-avatar-size: 5rem;
    }

    display: grid;
    grid-template-columns: var(--teammember-avatar-size) 1fr;
    gap: 1rem;

    &__avatar {
        width: var(--teammember-avatar-size);
        height: var(--teammember-avatar-size);
        border-radius: 50%;
        background-color: var(--color-scale-25);
        overflow: hidden;
        position: relative;
        top: -0.5rem;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__header {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        margin-bottom: 0.25rem;
    }
    &__name {
        margin: 0 0.5rem 0 0;
        line-height: 1;
        font-size: 1.125rem;
    }
    &__role {
        margin: 0;
        opacity: 0.7;
    }

    &__social-link {
        margin-block-end: 0.5rem;
    }
    &__message {
        p {
            margin: 0;
            &:empty {
                display: none;
            }
        }
    }
    &__morelink {
        display: inline-flex;
        align-items: center;
        gap: 0.5em;
        margin-top: 0.75rem;
    }
    &__reactions {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        padding-top: 0.5rem;
    }
    &__pill {
        @include button-reset();
        display: block;
        line-height: 1;
        font-family: $font-family-system;
        border-radius: 10rem;
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
        background-color: var(--color-scale-25);
        user-select: none;

        &:where(:hover, :focus) {
            cursor: pointer;
            background-color: var(--color-scale-25-offset);
            font-weight: 700;
        }

        &:active {
            transform: translateY(1px);
        }

        > span {
            opacity: 0.7;
            padding-left: 0.25em;
        }
    }
}

.teamimage {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #dfe5e9;
    overflow: hidden;

    img {
        display: block;
        height: auto;
        max-width: calc(100% + 4rem);
        margin: 0 -2rem;
    }
}

.teammember-term-cloud {
    padding-block: 3rem;

    &__members {
        display: flex;
        flex-direction: column;
    }

    &__member {
        display: flex;
        position: relative;

        &:nth-child(even) {
            justify-content: end;
        }
    }

    &__avatar {
        display: inline-grid;
        place-content: center;
        aspect-ratio: 1;
        width: clamp(100px, 14vw, 150px);
        border-radius: 50%;
        background-color: var(--color-scale-25);
    }

    &__terms {
    }

    &__term {
        font-size: 0.85em;
        font-weight: 600;
        line-height: 1;
        background-color: #fff;
        border: 2px solid var(--color-scale-25);
        padding: 0.57em 1.14em;
        border-radius: 2em;
        display: inline-block;
        position: absolute;
    }
}
