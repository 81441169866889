// a super generic thing block of heading/text pair. Just a thing.
.thing {
    //background-color: var(--color-scale-25);
    border: 1px solid var(--color-scale-25);
    //color: var(--color-scheme-accent);
    //border-radius: $border-radius-large;
    padding: 1.25em;

    &__title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.25;
        margin-block-start: 0;
        margin-block-end: 0.5em;
    }

    &__description {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        margin-block-start: 0;
        margin-block-end: 0;
    }
}
