.book-container {
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 600px;
    transform: scale(1.8);

    @include mq-down(lg) {
        transform: scale(1.5);
        margin: 4rem auto 8rem;
    }

    @include mq-down(md) {
        margin-top: 6rem;
    }
}

@keyframes initAnimation {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(-25deg);
    }
}

.book {
    width: 200px;
    height: 300px;
    position: relative;
    transform-style: preserve-3d;
    transform: rotateY(-25deg);
    transition: 1s ease;
    animation: 1s ease 0s 1 initAnimation;

    &:hover {
        transform: rotateY(0deg);
    }

    &::before {
        position: absolute;
        content: ' ';
        background-color: blue;
        left: 0;
        top: 3px;
        width: 23px;
        height: 294px;
        transform: translateX(184.5px) rotateY(90deg);
        background: linear-gradient(
            90deg,
            #fff 0%,
            #f9f9f9 5%,
            #fff 10%,
            #f9f9f9 15%,
            #fff 20%,
            #f9f9f9 25%,
            #fff 30%,
            #f9f9f9 35%,
            #fff 40%,
            #f9f9f9 45%,
            #fff 50%,
            #f9f9f9 55%,
            #fff 60%,
            #f9f9f9 65%,
            #fff 70%,
            #f9f9f9 75%,
            #fff 80%,
            #f9f9f9 85%,
            #fff 90%,
            #f9f9f9 95%,
            #fff 100%
        );
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: ' ';
        width: 200px;
        height: 300px;
        transform: translateZ(-12.5px);
        background-color: #001e2f;
        border-radius: 0 2px 2px 0;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    }

    &__cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 300px;
        transform: translateZ(12.5px);
        background-color: #001e2f;
        border-radius: 0 2px 2px 0;
        box-shadow: -10px 0 30px 5px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}
