// Toast Messages

.toast {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 2rem;
    border-radius: $border-radius $border-radius 0 0;

    will-change: transform;
    transform: translate(0, 100%);
    transition: transform 0.25s $animation-curve-fast-out-linear-in;
    pointer-events: none;
    z-index: z('messages');

    &__message {
        flex: 1 0 0%;
        padding-right: 1rem;
    }

    // Show Toast
    &--active {
        transform: translate(0, 0);
        pointer-events: auto;
        transition: transform 0.25s $animation-curve-linear-out-slow-in;
    }

    &--idle {
        visibility: hidden;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        clip-path: inset(50%);
        min-height: 0 !important;
        transition: none;
    }

    // Type Classes
    // related to django message tags
    &--default {
        color: var(--color-scale-0);
        background-color: var(--color-scale-100);
        border-color: var(--color-scale-100);
    }
    &--info {
        @extend .alert--info;
    }
    &--warning {
        @extend .alert--warning;
    }
    &--error {
        @extend .alert--error;
    }
    &--success {
        @extend .alert--success;
    }

    // Breakpoints
    @include mq(md) {
        width: auto;
        min-height: 48px;
        left: 5%;
        right: 5%;
    }
}
