// =========================================================================
// SUB-NAVIGATION
// =========================================================================
.subnav {
    display: none;
    position: relative;
    font-size:.875em;
    flex-basis: 100%;

    .nav__item.is-open > & {
        display: block;
    }

    &__link {
        color: var(--color-scale-100);
        display:block;
        padding: 0.75rem 1rem;
        white-space: nowrap;
    }
    @include mq-down(lg) {
        &__link {
            color: #FFF !important;
            padding-left: 2rem;
        }
    }
    @include mq(lg) {
        position: absolute;
        top: 100%;
        min-width: 240px;
        background-color: var(--color-scale-0);
        box-shadow: 6px 18px 30px -10px rgba(0,0,0,.1);
        border-radius: 4px;
        border: 1px solid var(--color-scale-25);
        transform: translateY(-1px);

        &__menu {
            padding:.25rem 0;
        }

        .nav__item:hover > & {
            display:block;
        }
    }
}

.nav__subarrow {
    display:inline-block;
    position: relative;
    transform: rotate(0);
    transition: transform 0.25s $animation-curve-default;

    .nav__item.is-open & {
        transform: rotate(-180deg);
    }
}
