.tab-container {
    background-color: var(--color-scale-0);
    box-shadow: $box-shadow-elevated;
    display: flex;
    flex-direction: column;
    --tab-container-padding: 1rem;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: var(--section-spacing);

    @at-root :root[data-theme='dark'] & {
        background-color: #011622;
    }

    @include mq(md) {
        flex-direction: row;
        --tab-container-padding: 1.5rem;
    }

    @include mq(lg) {
        --tab-container-padding: 3rem;
    }

    &__menu {
        background-color: var(--color-scale-25);

        @include mq(md) {
            flex: 1.5;
            text-align: right;
        }
    }

    &__content {
        padding: var(--tab-container-padding);
        text-align: left;

        @include mq(md) {
            flex: 3;
        }
    }
}

.tab-menu {
    font-size: 1rem;
    line-height: 1;

    @include mq(md) {
        font-size: 1.25em;
    }

    a {
        display: inline-flex;
        align-items: center;
        color: currentColor;
        width: 100%;
        padding: var(--tab-container-padding);
        font-weight: 600;
        background-color: transparent;
        transition-property: background-color, color;
        transition-duration: 250ms;
        transition-timing-function: $animation-curve-default;

        .capitals--num {
            font-size: 1em;
            font-weight: inherit;
            margin: 0;
        }

        @include hover-focus {
            background-color: hsla(0, 0%, 100%, 0.5);
        }

        @include mq(md) {
            justify-content: space-between;
        }

        &[aria-selected='true'] {
            &[aria-selected='true'] {
                color: #fff;
                background-color: var(--color-brand-primary);

                @include mq(md) {
                    //background-color: #fff;
                    //bac: var(--color-brand-primary);
                    position: relative;
                    --triangle-size: 1rem;

                    &::after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: var(--triangle-size) solid
                            transparent;
                        border-bottom: var(--triangle-size) solid
                            transparent;
                        border-left: var(--triangle-size) solid
                            var(--color-brand-primary);
                        position: absolute;
                        left: 100%;
                        top: calc(50% - var(--triangle-size));
                    }
                }
            }
        }
    }

    &__item + .tab-menu__item {
        border-top: 1px solid hsla(0, 0%, 0%, 0.15);
    }
}

.tab-container {
    .lead {
        margin-bottom: calc(var(--section-spacing) / 2);
        font-size: 1.3rem;
    }
    .bulletlist {
        //--bulletlist-marker-color: var(--color-brand-secondary);
        list-style-type: disc;
        text-align: left;
        padding-left: 1em;

        &.vers--two-cols {
            @include mq(md) {
                max-width: 80ch;
                column-count: 2;
                column-gap: 1rem;
            }
        }

        @include mq(lg) {
            font-size: 1.25rem;
        }

        li {
            padding-left: 0.5em;
            font-size: 1.3rem;

            &::before {
                display: none;
            }
        }
    }
}