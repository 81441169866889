// UTILITY CLASSES
// All of these have !important because they should always overrule any other style

// Layout Utils

.u-mt0 {
    margin-top: 0 !important;
}
.u-mt1 {
    margin-top: 1rem !important;
}
.u-mt2 {
    margin-top: 2rem !important;
}
.u-mt3 {
    margin-top: 3rem !important;
}
.u-mt4 {
    margin-top: 4rem !important;
}

.u-mb0 {
    margin-bottom: 0 !important;
}
.u-mb1 {
    margin-bottom: 1rem !important;
}
.u-mb2 {
    margin-bottom: 2rem !important;
}
.u-mb3 {
    margin-bottom: 3rem !important;
}
.u-mb4 {
    margin-bottom: 4rem !important;
}

.u-pt0 {
    padding-top: 0 !important;
}
.u-pt1 {
    padding-top: 1rem !important;
}
.u-pt2 {
    padding-top: 2rem !important;
}
.u-pt3 {
    padding-top: 3rem !important;
}
.u-pt4 {
    padding-top: 4rem !important;
}

.u-pb0 {
    padding-bottom: 0 !important;
}
.u-pb1 {
    padding-bottom: 1rem !important;
}
.u-pb2 {
    padding-bottom: 2rem !important;
}

.u-max-width-narrow {
    max-width: 17em;
    margin-left: auto;
    margin-right: auto;
}

.u-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.u-space-between {
    justify-content: space-between;
}

// Colors

.u-initialcolor {
    color: inherit !important;
}

// Text Utilities

.u-align-left {
    text-align: left !important;
}
.u-align-center {
    text-align: center !important;
}
.u-align-right {
    text-align: right !important;
}
.u-weight-400 {
    font-weight: 400 !important;
}
.u-no-glow {
    text-shadow: none !important;
}
.u-isolate {
    isolation: isolate !important;
}
// Display Utils

.u-mobile-only {
    @include mq(md) {
        display: none !important;
    }
}
.u-tablet-only {
    @include mq-down(md) {
        display: none !important;
    }
}
.u-desktop-only {
    @include mq-down(lg) {
        display: none !important;
    }
}
.u-print-only {
    @media not print {
        display: none !important;
    }
}
