.project-steps-container {
    --project-step-spacing-x: 3rem;
    --swiper-pagination-bottom: 0px;

    swiper-container::part(container) {
        padding-bottom: 1rem;
    }

    @include mq(lg) {
        --project-step-spacing-x: 0;
    }
}

.projectstep {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    z-index: 2;
    background-color: var(--color-scale-0);
    padding: 1.5rem;
    margin: 0 var(--project-step-spacing-x);
    border: 1px solid var(--color-scale-25);

    &__num {
        position: absolute;
        top:1.5rem;
        left:1.5rem;
    }
    &__icon {
        font-size: 3rem;
        margin-bottom:1rem;
        margin-top: 1rem;
    }
    &__title {
        font-size: 1.5rem;
    }
    &__desc {
        @include hyphenate;
        text-align: left;
    }
    // &__details {
    //     summary {
    //         display: block;
    //         cursor:pointer;
    //         padding-bottom:1rem;
    //         outline: 0;
    //     }
    // }
    // &__more {
    //     @include hyphenate;
    //     display: inline-block;
    //     font-size: 1rem;
    //     color: var(--color-scale-100);
    //     text-decoration: none;
    //     position: relative;
    //     line-height: 1;
    //     transition: color 0.2s ease;

    //     &::after {
    //         content: '';
    //         display: block;
    //         position: absolute;
    //         z-index: -1;
    //         top: 60%;
    //         left: 0.25em;
    //         right: 0.25em;
    //         height: 0.5em;
    //         background-color: var(--color-brand-secondary);
    //         opacity: 0.75;
    //         transition: transform 0.2s ease, opacity 0.2s ease;
    //     }

    //     @include hover-focus {
    //         color: inherit;
    
    //         &::after {
    //             transform: translateY(-75%) scaleX(1.1) scaleY(2.5);
    //             opacity: 1;
    //         }
    //     }
    // }
}