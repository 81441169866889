.nav {
    &__content {
        position: relative;
        z-index: z('nav');
        display: flex;
        align-items: center;
    }
    &__menu {
        display: flex;
    }
    &__item {
        position: relative;
        font-weight: 600;
        margin-left: 2rem;

        &--with-submenu {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        padding: 1rem;
        line-height: 1;
        color: currentColor;
        text-decoration: none;

        @include mq(lg) {
            &--cta {
                background-color: var(--color-scale-25);
                border-radius: 10em;
                padding: 1rem 2rem 0.875rem 2rem;

                @include hover-focus {
                    color: #FFF;
                    background-color: $brand-primary;
                }
            }
        }
    }
    &__lang {
        // language nav container
        position: absolute;
        z-index: z('content-overlay');
        right: 0;
        top: 50vh;
        transform: rotate(-90deg);

        @media (min-height: 900px) {
            top: 450px;
        }
    }
    &__darkmode {
        display: none;
        margin-left:.5rem;

        @supports (color: var(--fake-var)) {
            display: block;
        }
        @include mq(lg) {
            margin: 0 0 0 1.5rem;
            transform: translateY(-1px);
        }
    }
    &__togglebtn {
        @include button-reset;
        display: none;
    }
    &__subtoggle {
        @include button-reset;
        padding:.25rem;
        margin-left:-1rem;
    }

    @include mq-down(lg) {
        &__content {
            @include scrollable;
            display: none;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding-top: 100px;
            padding-bottom:1rem;
            flex-direction: column;
            align-items: stretch;
            color: $gray-lightest;
            background-color: $gray-darkest;

            .nav-open & {
                display: flex;
            }
        }
        &__menu {
            display: flex;
            flex: 1;
            flex-direction: column;
        }
        &__item {
            margin: 0;
            font-size: 1.125rem;
            animation-name: fadeInUp;
            animation-duration: 0.4s;
            animation-fill-mode: backwards;

            &--with-submenu {
                @include hover-focus {
                    color: inherit;
                    background-color: $brand-primary;
                }
            }

            @for $i from 1 through 6 {
                &:nth-child(#{$i}) {
                    animation-delay: #{($i - 1) * 0.05}s;
                }
            }
        }
        &__link {
            padding: 1.25rem 1.5rem;

            @include hover-focus {
                color: inherit;
                background-color: $brand-primary;
            }
        }
        &__lang {
            display:none;
        }
        &__togglebtn {
            display: block;
            padding: 8px 10px;
            position: relative;
            z-index: z('nav') + 1;

            .nav-open & {
                color: $gray-lightest;
            }
        }
        &__subtoggle {
            display:flex;
            flex: 1 0 auto;
            align-items: center;
            justify-content: flex-start;
        }
    }
}

@include mq-down(lg) {
    .nav-open .header .logo__type {
        fill: $gray-lightest;
    }
}
