// Font Face Declarations

// EINA

// Regular
@font-face {
    font-family: 'Eina';
    src: url('/static/fonts/eina/eina-400-regular.woff2') format('woff2'),
        url('/static/fonts/eina/eina-400-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
}

// Semibold
@font-face {
    font-family: 'Eina';
    src: url('/static/fonts/eina/eina-600-semibold.woff2') format('woff2'),
        url('/static/fonts/eina/eina-600-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    unicode-range: U+000-5FF;
}
