.logobar {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

    @include mq(md) {
        grid-gap: 4rem;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__link {
        display: block;
        filter: grayscale(1);
        opacity: 0.75;
        transition: filter 0.2s, opacity 0.2s;

        .logobar__item--highcontrast & {
            opacity: 0.55;
        }

        @include hover-focus {
            filter: grayscale(0);
            opacity: 1;
        }
    }
    &__img {
        width: auto;
        height: 60px;
    }
}

.logobar__item--square {
    .logobar__img {
        height: 100px;
    }
    @include mq(lg) {
        justify-content: flex-start;
    }
}
