//--------------------
// SCSS VARIABLES
//--------------------

@import '~bootstrap/scss/_variables.scss';

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    s: 480px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
$container-max-width: 1280px;

// Z-Index Stack
//
// Control the order of layers in the application
$z-layers: (
    'modal': 999,
    'messages': 200,
    'nav': 100,
    'header': 50,
    'content-overlay': 25
);

// Grayscale

$gray-darkest: #001e2f;
$gray-dark: #272f34;
$gray: #525252;
$gray-light: #e6f1f8;
$gray-lighter: #f7f7f7;
$gray-lightest: #fff;

// Brand Colors

$brand-primary: #6b5aed;
$brand-secondary: #26ffae;
$brand-tertiary: #ed667b;
$brand-tack: #ff0547;

// State Colors

$state-success: #95b34f;
$state-info: #498cbd;
$state-warning: #df8a13;
$state-error: #d81e05;

// Links
//
// Style anchor elements.

$link-decoration: none;
$link-hover-decoration: none;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-brand: 'Eina';
$font-family-monospace: 'Fira Code', Menlo, Monaco, Consolas, 'Andale Mono',
    'Courier New', monospace;
$font-family-base: $font-family-brand, $font-family-system;

$font-size-root: 100%;
$font-size-base: 1rem;
$line-height-base: 1.5;

// Animation
//
// Default easing curves, Transitions, etc

$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
$animation-curve-default: $animation-curve-fast-out-slow-in;

// Misc Shared
//
// common shared styles

$border-radius: 0.25rem;
$spacing-x: 2rem;
$spacing-y: 2rem;

$box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
$box-shadow-elevated: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
$box-shadow-card:
    0px 1px 1px rgba(0, 0, 0, 0.05),
    0px 2px 2px rgba(0, 0, 0, 0.05),
    0px 4px 6px rgba(0, 0, 0, 0.01),
    0px 6px 9px rgba(0, 0, 0, 0.03);

// Components
//
// Variables related to specific elements

$header-height: 50px;
$streamfield-paragraphs-width: 44rem;
$streamfield-embededs-width: 56rem;
$richtext-flow-spacing: 1.5rem;
$device-card-image-overlap: 8rem;
$container-padding-mobile: 1rem;
$container-padding-desktop: 4rem;
