.newsletter-teaser {
    background-color: var(--color-scale-0);
    box-shadow: $box-shadow-card;
    border-radius: $border-radius;
    padding: 1rem;
    margin-inline: auto;
    margin-block: 2rem;

    @include mq(md) {
        padding: 2rem;
    }

    @include mq(lg) {
        padding: 3rem;
        text-align: center;
    }

    & > * + * {
        margin-block-start: 0.75rem;
    }

    &__heading {
        font-size: 1.75rem;
        font-weight: 600;
    }

    &__content {
        p {
            font-size: 1rem;
            text-wrap: balance;
        }
    }
}
