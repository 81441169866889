.grid {
    display: flex;
    flex-wrap: wrap;

    display: grid;
    grid-gap: 2rem;

    @include mq(lg) {
        grid-gap: 4rem;
        margin: 0 -2rem;

        > * {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &--2col {
            grid-template-columns: repeat(2, 1fr);

            > * {
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &--3col {
            grid-template-columns: repeat(3, 1fr);

            > * {
                flex-basis: 33.33%;
                max-width: 33.33%;
            }
        }
        &--4col {
            grid-template-columns: repeat(4, 1fr);

            > * {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        @supports (display: grid) {
            margin: 0;

            > * {
                flex: none;
                max-width: none;
                padding: 0;
            }
        }
    }
}
