.google-rating {
    a {
        display: block;
        width: 200px;
        overflow: hidden;
        border-radius: 8px;
        border: 2px solid var(--color-scale-25);

        @include hover-focus {
            border-color: #DEDEDE;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}