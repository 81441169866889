.whitepaper {
    &__wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3rem;
        justify-content: center;
        margin-bottom: 5rem;

        @include mq-down(lg) {
            display: flex;
            flex-direction: column;
        }
    }

    .block-paragraph {
        max-width: 100%;
    }

    .signup {
        &__inner {
            display: flex;
            flex-direction: column;
        }
        &__content {
            @include mq(md) {
                text-align: left;
                border-bottom: 1px solid #e6f1f8;
                max-width: none;
            }
        }
        &__form {
            @include mq(md) {
                max-width: none;
            }
        }
    }
}
