// Main Site Layout

body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    &.no-scroll {
        position: fixed;
        overflow: hidden;
    }

    &.hide-logo:not(.nav-open) {
        .header__brand {
            display: none;
        }
    }
}

// Default Layout

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: 100vh;
    position: relative;

    .header,
    .footer {
        flex: none;
    }

    .main {
        flex: 1 0 auto;

        &::after {
            content: '';
            display: block;
            height: 0px;
            visibility: hidden;
        }
    }
}

.container {
    width: 100%;
    max-width: $container-max-width;
    padding-left: $container-padding-mobile;
    padding-right: $container-padding-mobile;
    margin-left: auto;
    margin-right: auto;

    @include mq(md) {
        padding-left: $container-padding-desktop;
        padding-right: $container-padding-desktop;
    }

    &--full {
        max-width: none;
    }
}
