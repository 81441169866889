.office {
    color: white;
    background-color: black;
    position: relative;
    overflow: hidden;

    &__address {
        padding-top: $spacing-y * 2;
        padding-bottom: $spacing-y * 2;

        a {
            color: inherit;
            text-decoration: underline;

            @include hover-focus {
                text-decoration: none;
            }
        }
    }
    &__image {
        position: relative;
        height: 250px;
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);

        img {
            @include coverall;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @include mq(md) {
        &__address {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 390px;
            max-width: 33%;
            padding-right: 2rem;
        }
        &__image {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 40%;
            height: 100%;
            margin: 0;
        }
    }
}
