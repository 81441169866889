// aspect ratio containers
.responsive-object {
    position: relative;
    overflow: hidden;
    height: 0;

    &--16-9 {
        padding-bottom: 56.25% !important;
    }

    &--4-3 {
        padding-bottom: 75% !important;
    }

    video,
    iframe {
        @include coverall;
        z-index: 2;
        width: 100% !important;
        height: 100% !important;
    }
}

// custom bullet lists
.bulletlist {
    list-style-type: none;

    li {
        position: relative;
        padding: 0.25rem 0;
        padding-left: 2rem;

        &::marker {
            color: var(--bulletlist-marker-color, var(--color-brand-primary));
        }

        &::before {
            content: '';
            display: block;
            width: 0.75em;
            height: 0.25em;
            background-color: var(
                --bulletlist-marker-color,
                var(--color-brand-primary)
            );
            position: absolute;
            top: 0.75em;
            left: 0;
        }
    }

    &--neutral li::before {
        background-color: currentColor !important;
    }
}

// Cursor

.cursor {
    display: inline-block;
    width: 24px;
    height: 6px;

    @include mq(md) {
        width: 40px;
        height: 12px;
    }

    &--primary {
        background-color: var(--color-brand-primary);
    }
    &--secondary {
        background-color: var(--color-brand-secondary);
    }
    &--tertiary {
        background-color: var(--color-brand-tertiary);
    }
}

.badge {
    font-family: $font-family-system;
    font-size: 0.25em;
    font-weight: 500;
    letter-spacing: 0em;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    text-align: center;
    line-height: 1;
    color: var(--color-scale-50);

    &--beta {
        vertical-align: super;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: var(--color-scale-25);
    }
}

.cta-message {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > p {
        flex-basis: 100%;
        text-wrap: balance;

        @include mq(lg) {
            flex: 1 0 0%;
            margin: 0;
            padding-right: 1rem;
        }
    }
}

.img-container img {
    width: 100%;
    height: auto;
}
