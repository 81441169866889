// ===================
// NEON GLOW FX
// ====================
.hero__title,
.landinghero__title,
.page__title,
.post__link:hover,
.ctalink--primary,
.u-glow {
    @include neon-glow(var(--color-brand-primary));
}
.section__title,
.ctalink--secondary {
    @include neon-glow(var(--color-brand-secondary));
}
.ctalink--tertiary {
    @include neon-glow(var(--color-brand-tertiary));
}
.post:hover {
    box-shadow: 0 0 24px -5px var(--color-brand-primary);
}

// ===================
// NEON FLICKER ANIMATION
// ====================

.u-flicker {
    animation: flicker 3s linear forwards alternate infinite;

    &:nth-child(even) {
        animation-delay: 0.3s;
        animation-direction: alternate-reverse;
    }
}

// ===================
// LIGHTSWITCH STATE
// ====================

.lightswitch__icon {
    &__on {
        display: none;
    }
    &__off {
        display: block;
    }
}

// ===================
// MISC COLOR ADJUSTMENTS
// ====================

.logobar__link {
    filter: grayscale(1) invert(1) brightness(1);
    @include hover-focus {
        filter: grayscale(1) invert(1) brightness(1.4);
    }
}
.rich-text {
    a:not(.btn) {
        outline-color: var(--color-brand-primary);
        background-image: linear-gradient(
            to top,
            var(--color-brand-primary) 40%,
            transparent 40%
        );

        @include hover-focus {
            background-image: linear-gradient(
                to top,
                var(--color-brand-primary) 0%,
                var(--color-brand-primary) 100%
            );
        }
    }
    :not(pre) code {
        background-color: rgba(#6b5aed, 0.25);
        border-color: lighten(#6b5aed, 5%);
        color: var(--color-scale-100);
    }
}
.syntax {
    background-color: #011b26;
    border: 1px solid var(--color-scale-25);
}
.post__lang,
.alert--info {
    color: #d2f1f6;
    background-color: #133852;
    border-color: #3d80b1;
}
.office {
    background-color: var(--color-scale-25);
}
.section--inverted {
    color: var(--color-scale-100);
    background-color: var(--color-scale-25);
}
.section--muted {
    background-color: var(--color-scale-0);
}
.scrollhint--inverted {
    background-color: var(--color-scale-0);
}
.actionsteps__icon {
    color: var(--color-scale-100);
}
.footer {
    background-color: var(--color-scale-25);
    color: var(--color-scale-75);
}

// ===================
// BREAKPOINTS
// ====================

@include mq(md) {
    .header {
        border-color: var(--color-scale-50);
    }
}

@include mq(lg) {
    .nav__link {
        @include hover-focus {
            @include neon-glow(var(--color-brand-secondary));
        }
    }
    .nav__link--cta {
        @include hover-focus {
            text-shadow: none !important;
            color: var(--color-scale-0);
            background-color: var(--color-brand-secondary);
            box-shadow: 0 0 32px -4px var(--color-brand-secondary);
        }
    }
}
