$collage-grid-gap: 2rem;

.collage {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-left: calc(var(--spacing-x) * -1);
    margin-right: calc(var(--spacing-x) * -1);

    @include mq(md) {
        display: grid;
        grid-gap: $collage-grid-gap;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(6, 200px);
    }
    @include mq(lg) {
        margin: 0;
    }

    &__image {
        position: relative;
        min-height: $collage-grid-gap;
        margin-bottom: $collage-grid-gap;

        img {
            width: 100%;
            max-width: 700px;
            object-fit: cover;
            vertical-align: bottom;
        }

        @supports (display: grid) {
            background-color: var(--color-scale-25);

            @include mq(md) {
                margin: 0;

                img {
                    @include coverall;
                    max-width: none;
                    height: 100%;
                }
            }
        }

        &--1 {
            grid-row: 1 / span 2;
            grid-column: 1 / span 7;
        }
        &--2 {
            grid-row: 2 / 3;
            grid-column: 8 / span 3;
        }
        &--3 {
            grid-row: 3 / 4;
            grid-column: 3 / span 3;
        }
        &--4 {
            grid-row: 4 / 5;
            grid-column: 3 / span 3;
        }
        &--5 {
            grid-row: 3 / span 2;
            grid-column: 6 / -1;
        }
        &--5 {
            grid-row: 3 / span 2;
            grid-column: 6 / -1;
        }
        &--6 {
            grid-row: 5 / 7;
            grid-column: 1 / span 8;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        margin: $collage-grid-gap;

        @include mq(md) {
            margin: 0;

            &--1 {
                padding-bottom: $collage-grid-gap;
            }
            &--2 {
                padding-top: $collage-grid-gap;
            }
        }

        h4 {
            margin-bottom: 0.5rem;
        }
        p {
            margin: 0;
        }

        &--1 {
            grid-row: 1 / 2;
            grid-column: 8 / span 3;
            justify-content: flex-end;
        }
        &--2 {
            grid-row: 5 / 6;
            grid-column: 9 / span 3;
        }
    }

    &__cursor {
        @include mq-down(md) {
            display: none !important;
        }

        &--1 {
            grid-row: 3;
            grid-column: 3;
            transform-origin: 0 0;
            transform: translateX(-$collage-grid-gap) rotate(90deg);
        }
        &--2 {
            grid-row: 2;
            grid-column: 8;
            transform: translateY(-100%) translateY(-$collage-grid-gap);
        }
        &--3 {
            grid-row: 5;
            grid-column: 9;
        }
    }
}
