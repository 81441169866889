.scrollhint {
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--color-scale-25);
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        height: 2rem;
        background: var(--color-scale-100);
        width: 1px;
        top: -2rem;
        transform: scaleY(1);
        transform-origin: 0 0;
        animation: scroll-hint 2s ease infinite;
    }

    &--inverted {
        background-color: $gray-dark;

        &::before {
            background-color: $gray-light;
        }
    }
}

.scrollhint-container {
    width: 1px;
    height: 200px;
    margin: 0 auto;
    position: relative;

    &--project {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        height: auto;
        margin: 0 0 0 -2rem;

        @include mq(md) {
            display: block;
        }
    }
}

@keyframes scroll-hint {
    from {
        top: -2rem;
        transform: scaleY(1);
    }
    to {
        top: 100%;
        transform: scaleY(2);
    }
}
