// Alert Component

.alert {
    position: relative;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    padding: 1rem;
    font-size: 1rem;

    .icon {
        margin-right: 0.5rem;
    }

    .btn {
        font-size: 1rem;
    }

    a {
        font-weight: bold;
        color: inherit;
        text-decoration: underline;

        @include hover-focus {
            text-decoration: none;
        }
    }

    p {
        margin: 0;
    }

    // Variations
    &--info {
        color: darken($state-info, 10%);
        background-color: lighten($state-info, 45%);
        border-color: lighten($state-info, 35%);
    }
    &--warning {
        color: darken($state-warning, 10%);
        border-color: lighten($state-warning, 25%);
        background-color: lighten($state-warning, 40%);
    }
    &--success {
        color: darken($state-success, 15%);
        border-color: lighten($state-success, 25%);
        background-color: lighten($state-success, 40%);
    }
    &--error {
        color: darken($state-error, 10%);
        border-color: lighten($state-error, 15%);
        background-color: desaturate(lighten($state-error, 52%), 25%);
    }
}
