// ToolTip

.has-tooltip {
    &[aria-label] {
        position: relative;

        &::after {
            display: block;
            content: attr(aria-label);
            width: auto;
            position: absolute;
            z-index: -1;
            bottom: 100%;
            left: 50%;
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
            color: var(--color-scale-0);
            background-color: var(--color-scale-100);
            border-radius: $border-radius;
            opacity: 0;
            transform: translate(-50%, 0);
            transition: all 0.2s ease;
            pointer-events: none;
        }
        &:hover::after {
            display: block;
            opacity: 1;
            z-index: z('content-overlay');
            transform: translate(-50%, -0.25rem);
        }
    }
}

.has-tooltip--large[aria-label] {
    &::after {
        width: 18em;
    }
}
