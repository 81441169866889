// Button Component

.btn {
    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 2px solid transparent;
    font-size: 0.875rem; //14px
    font-weight: bold;
    padding: 0.28571em 1em;
    border-radius: 10em;
    text-decoration: none;
    -webkit-appearance: none;

    &:disabled,
    &--disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.65;
    }
}

.btn--large {
    font-size: 1rem;
    padding: 1rem 2rem 0.875rem 2rem;
    white-space: normal;
}

// Block Modifier for full-width
.btn--block {
    display: flex;
    width: 100%;

    & + & {
        margin-top: 1rem;
    }
}

// Primary CTA Buttons
.btn--primary {
    color: $gray-lightest;
    background-color: var(--color-brand-primary);
    transition:
        color 0.25s linear,
        background-color 0.25s linear;

    @include hover-focus {
        color: $gray-lightest;
        background-color: var(--color-brand-primary-offset);
    }
}

.btn--secondary {
    color: var(--color-brand-primary);
    border-color: var(--color-brand-primary-offset);
    background-color: transparent;
    transition:
        color 0.25s linear,
        background-color 0.25s linear;

    @at-root :root[data-theme='dark'] & {
        color: var(--color-brand-primary-offset);
    }

    @include hover-focus {
        color: $gray-lightest !important;
        background-color: var(--color-brand-primary);

        @at-root :root[data-theme='dark'] & {
            background-color: var(--color-brand-primary-offset);
        }
    }
}

.btn--muted {
    color: var(--color-scale-75);
    background-color: var(--color-scale-25);

    @include hover-focus {
        color: var(--color-scale-75);
        background-color: var(--color-scale-25-offset);
    }
}

.btn--link {
    @include button-reset;
    font-weight: 400;
    color: var(--color-brand-primary);

    @include hover-focus {
        text-decoration: underline;
    }
}

.btn--tertiary {
    color: $gray-lightest;
    background-color: var(--color-brand-tertiary);
    transition:
        color 0.25s linear,
        background-color 0.25s linear;

    @include hover-focus {
        color: $gray-lightest;
        background-color: var(--color-brand-tertiary-offset);
    }
}

.btn--copy-to-clipboard {
    @include button-reset();
    margin-inline-start: 0.25em;
    line-height: 1;
    color: var(--color-scale-50);
    transform: translateY(-25%);
    //vertical-align: super;

    svg {
        display: block;
        height: 0.85em;
        width: auto;
    }

    &:where(:hover, :focus) {
        color: var(--color-brand-primary-offset);
    }

    &:active {
        transform: translateY(-15%);
    }
}
