.pill {
    display: inline-flex;
    line-height: 1;
    border: 2px solid var(--color-scale-25);
    border-radius: 3em;
    padding-block: 0.5rem calc(0.5rem - 2px); // compensate for the text character height
    padding-inline: 1rem;
    font-weight: 600;
    align-items: center;
    gap: 0.25em;
}
