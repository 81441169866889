.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: relative;
    min-height: 350px;

    @include mq(lg) {
        min-height: 70vh;
    }

    &__inner {
        padding-top: $spacing-y;
        padding-bottom: $spacing-y * 2;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include mq(lg) {
            padding-block: $spacing-y * 3;
            flex-direction: row;
            gap: 3rem;
            align-items: center;
        }
    }

    &__text {
        &:only-child {
            @include mq(lg) {
                margin: 0 auto;
                text-align: center;

                .hero__actions {
                    justify-content: center;
                }
            }
        }
    }

    &__side {
        display: none;

        @include mq(lg) {
            display: block;
        }

        .device-pill {
            --device-pill-rotation: 0deg;
            --container-padding: #{$container-padding-mobile};
            background-color: var(--color-scale-25);
            transform: rotate(var(--device-pill-rotation));
            overflow: hidden;

            @include mq-down(lg) {
                height: 15vh;
                margin-inline: calc(var(--container-padding) * -1);
            }

            @include mq(md) {
                --container-padding: #{$container-padding-desktop};
            }

            @include mq(lg) {
                --device-pill-rotation: 15deg;
                height: 100%;
                width: clamp(250px, 22vw, 290px);
                border-radius: 500px;
                aspect-ratio: 3/7;
            }

            > div {
                height: 100%;
                width: 100%;
            }

            img {
                display: block;
                max-width: none;
                position: absolute;
                inset-inline-start: 50%;
                inset-block-start: 50%;
                transform: translate(
                        -50%,
                        calc(-50% + var(--parallax-offset, 0px))
                    )
                    rotate(calc(var(--device-pill-rotation) * -1)); // offset the rotation, so it looks unrotated again.
                width: 125%;
                height: auto;

                @include mq(lg) {
                    --parallax-offset: 90px;
                    height: 100%;
                    width: auto;
                }
            }
        }
    }

    &__logo {
        display: inline-block;

        > svg {
            display: inline-block;
            height: 3rem;
            width: auto;
        }
    }

    &__title {
        @extend h1;
        margin-block: 1rem;
        text-wrap: balance;

        @include mq(lg) {
            margin-block: 2rem;
            font-size: 4rem;
        }
    }
    &__subtitle {
        max-width: 36em;
        margin-block-start: 0;
        margin-bottom: 1rem;
        margin-inline-start: 0;
        margin-right: auto;
        text-wrap: balance;

        @include mq(lg) {
            margin-bottom: 2rem;
            font-size: 1.375rem;
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }
    &__scrollhint {
        position: absolute;
        z-index: -1;
        top: calc(50% + 150px);
        bottom: 0;

        @include mq(lg) {
            top: calc(50% + 150px);
        }
    }
}
