// Styles for Content generated through Streamfield Blocks
$streamfield-block-spacing-y: 4rem;

.streamfield {
    // base streamfield styles
    text-align: left;
    font-size: 1.125rem; //18px
    font-weight: 400;
    line-height: 1.75;

    max-width: 62rem;
    margin: 0 auto;

    @include mq(md) {
        font-size: 1.3125rem; //21px
    }
    @media (min-width: 1600px) and (min-height: 850px) {
        max-width: none;
    }

    // center embeds
    .twitter-tweet-rendered,
    .instagram-media-rendered {
        // has to overwrite inline styles
        margin-left: auto !important;
        margin-right: auto !important;
    }

    // ============= BLOCK STYLES ==============
    // these are content blocks defined in wagtail

    [class^='block-'] {
        margin: 0 auto $streamfield-block-spacing-y / 2;

        &[class='block-highlight'] {
            margin: 0 auto $richtext-flow-spacing;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include mq(md) {
            margin-bottom: $streamfield-block-spacing-y;
        }
    }

    // Paragraph Block
    .block-paragraph {
        @extend %richtext;
        max-width: $streamfield-paragraphs-width;

        & + .block-highlight {
            @include mq('md') {
                margin-block-start: -$streamfield-block-spacing-y +
                    $richtext-flow-spacing;
            }

            &:last-child {
                margin-block-end: $richtext-flow-spacing;
            }
        }
    }
    .block-code,
    .block-embed {
        max-width: $streamfield-embededs-width;
    }

    // Highlight-block
    .block-highlight {
        $block-highlight-padding: 1.25em;
        @extend %richtext;
        max-width: calc(
            #{$streamfield-paragraphs-width} + #{$block-highlight-padding} * 2
        );

        & > div {
            background-color: $light;
            color: $gray-dark;
            padding: $block-highlight-padding;
            border-radius: $border-radius;

            &[class*='color-scheme'] {
                background-color: var(--color-scheme-base);
                color: var(--color-scheme-accent);
            }

            &.color-scheme--dark {
                --link-color-scheme: #{$brand-primary};
            }

            & > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Image Block
    .block-image {
        overflow: hidden;
        text-align: center;

        // images
        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            vertical-align: bottom;

            &.bordered {
                border: 2px solid var(--color-scale-25);
            }
        }

        // lazyload
        img[data-src] {
            opacity: 0.5;
            filter: blur(15px);
            filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='15');

            &.is-loaded {
                opacity: 1;
                filter: none;
            }
        }

        figcaption {
            font-weight: 400;
            opacity: 0.75;
            font-size: 0.9375rem; //15px;
            margin-top: 0.75rem;

            p {
                margin: 0;
            }
        }
    }
    .block-image + .block-image {
        margin-top: $streamfield-block-spacing-y * -0.5;
    }

    // Video Block
    .block-video {
        text-align: center;
        clip-path: inset(2px 2px 2px 2px);

        video {
            max-width: 100%;
            height: auto;
        }
    }

    // Embed Block
    .block-embed {
        > div {
            position: relative;
            padding-bottom: 56.25%;
        }

        iframe {
            @include coverall;
            width: 100% !important;
            height: 100%;
            z-index: 1;
        }
    }

    // Code Block
    .block-code {
        font-size: 1rem;
        font-family: $font-family-monospace;
        line-height: 1.85;
        direction: ltr;
        text-align: left;
        word-spacing: normal;
        tab-size: 4;
        hyphens: none;

        margin-left: -1rem;
        margin-right: -1rem;

        .syntax {
            padding: 1.5rem 2.5rem;
            overflow-x: auto;
        }

        @include mq(lg) {
            margin-left: auto;
            margin-right: auto;
            font-size: 1.125rem;

            .syntax {
                border-radius: 0.5rem;
            }
        }
    }

    // 2Col Layout Block
    .block-two_columns {
        display: flex;
        flex-wrap: wrap;

        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $streamfield-block-spacing-y / 2;
        width: 100%;

        .col {
            flex-basis: 100%;
        }

        [class^='block-']:first-child {
            margin-top: 0;
        }
        [class^='block-']:last-child {
            margin-bottom: 0;
        }

        @include mq(lg) {
            grid-template-columns: 1fr 1fr;
            grid-gap: $streamfield-block-spacing-y;

            .col {
                display: flex;
                flex-basis: 50%;
                max-width: 50%;

                flex-direction: column;
                justify-content: center;

                .instagram-media-rendered {
                    min-width: 400px !important;
                }
            }
        }

        @supports (display: grid) {
            .col {
                flex: none !important;
                max-width: none !important;
            }
        }
    }

    .block-nl_signup {
        max-width: $streamfield-paragraphs-width;
    }

    .block-html {
        table {
            width: 100%;
            border-collapse: collapse;
            font-family: Arial, sans-serif;
            font-size: $font-size-base;

            th,
            td {
                padding: 1em 2em;
                text-align: left;
            }

            th {
                @extend .capitals;
                background-color: var(--color-scale-0);
                color: var(--color-scale-50);

                @at-root :root[data-theme='dark'] & {
                    color: var(--color-scale-75);
                }
            }

            tr:nth-child(odd) {
                background-color: var(--color-scale-15);
            }

            tr:hover {
                background-color: var(--color-scale-25);
            }

            td:first-child {
                font-weight: bold;
            }

            th,
            td {
                border-bottom: 1px solid hsla(0, 0%, 0%, 0.15);

                @at-root :root[data-theme='dark'] & {
                    border-color: hsla(0, 0%, 100%, 0.15) !important;
                }
            }
        }
    }
}

.streamfield--align-left {
    margin-left: 0;
    margin-right: 0;

    [class^='block-'] {
        margin-left: 0;
        margin-right: 0;
    }
}
