.comparison-table {
    --table-spacing: 1.5rem;
    --table-positive-color: var(--color-brand-secondary);
    margin: var(--section-spacing) auto;
    text-align: left;
    background-color: hsla(0, 0%, 100%, 0.03);
    border-radius: 10px;
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    max-width: 850px;
    padding: calc(var(--table-spacing) / 1.25) 0;
    overflow: hidden;

    @at-root :root[data-theme='dark'] & {
        --table-positive-color: var(--color-brand-secondary-offset);
    }

    @include mq(md) {
        display: flex;
        font-size: 0.85em; // so that all the text is visible, when it breaks into a two-column layout here
        --table-spacing: 2rem;
    }

    @include mq(lg) {
        font-size: 1em;
        --table-spacing: 3rem;
    }

    ul {
        li {
            list-style-position: inside;
            line-height: 3;
            padding: 0 var(--table-spacing);

            @include mq(md) {
                padding-left: var(--table-spacing);
                white-space: nowrap;
            }

            &::before {
                display: inline-block;
                font-size: 1.5em;
                line-height: 1;
                margin-right: 0.25em;
            }

            &:nth-child(even) {
                background-color: hsla(0, 0%, 100%, 0.05);
            }
        }
    }

    &__col {
        flex: 1;

        .capitals {
            margin-bottom: calc(var(--table-spacing) / 1.5);
            padding: 0 var(--table-spacing);

            @include mq(md) {
                padding-left: var(--table-spacing);
            }
        }

        & + .comparison-table__col {
            border-top: inherit;
            margin-top: var(--table-spacing);
            padding-top: var(--table-spacing);

            @include mq(md) {
                border-top: none;
                margin-top: 0;
                padding-top: 0;
            }

            .capitals {
                font-weight: 700;
                color: var(--table-positive-color);

                @at-root :root[data-theme='dark'] & {
                    @include neon-glow($brand-secondary);
                }

                @include mq(md) {
                    padding-left: 0;
                    padding-right: var(--table-spacing);
                }
            }

            ul {
                li {
                    @include mq(md) {
                        padding-left: 0;
                        padding-right: var(--table-spacing);
                    }

                    &::before {
                        content: '\2713';
                        color: var(--table-positive-color);

                        @at-root :root[data-theme='dark'] & {
                            @include neon-glow($brand-secondary);
                        }
                    }
                }
            }
        }
    }
}
