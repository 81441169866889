.productteaser {
    margin: 2rem 0 6rem;
    color: var(--color-scale-0);

    .capitals {
        opacity: 1;
        font-weight: 700;
    }

    &__inner {
        padding-top: 4rem;
        padding-bottom: 4rem;
        display: flex;
        flex-direction: column;
    }
    &__content {
        margin-bottom: 2rem;
    }
    @include mq(lg) {
        &__inner {
            flex-direction: row;
        }
        &__content {
            flex: 0 0 33%;
            max-width: 33%;
            padding-right: 2rem;
            margin-bottom: 0;
        }
        &__video {
            flex: 1 0 66%;
            max-width: 66%;

            iframe {
                box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
            }
        }
    }
    @include mq(xl) {
        &__video {
            margin-top: -6rem;
            margin-bottom: -6rem;
        }
    }
}
