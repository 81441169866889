// CTA Links

.ctalink {
    @include hyphenate;
    display: inline-block;
    font-size: 1.3125rem; //21px
    font-weight: 600;
    color: var(--color-scale-100);
    text-decoration: none;
    position: relative;
    line-height: 1;
    transition: color 0.2s ease;

    @include mq(md) {
        font-size: 1.625rem; //26px
    }

    @include hover-focus {
        color: inherit;

        &::after {
            transform: translateY(-75%) scaleX(1.1) scaleY(2.5);
            opacity: 1;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 60%;
        left: 0.25em;
        right: 0.25em;
        height: 0.5em;
        background-color: var(--color-brand-primary);
        opacity: 0.75;
        transition:
            transform 0.2s ease,
            opacity 0.2s ease;
    }

    &--small {
        font-size: 1rem;
    }

    &--medium {
        font-size: 1.625rem; //26px
        @include mq(md) {
            font-size: 2.25rem; // 36px
        }
    }
    &--large {
        font-size: 2rem; //32px
        @include mq(md) {
            font-size: 3rem; // 48px
        }
    }
    &--inverted {
        color: $gray-lightest;
        &::after {
            opacity: 0.9;
        }
    }
    &--primary {
        outline-color: var(--color-brand-primary) !important;
        @include hover-focus {
            color: $gray-lightest;
        }
        &::after {
            color: var(--color-brand-primary);
            background-color: var(--color-brand-primary);
        }
    }
    &--secondary {
        outline-color: var(--color-brand-secondary) !important;
        &::after {
            color: var(--color-brand-secondary);
            background-color: var(--color-brand-secondary);
        }

        &:focus-visible {
            outline-color: var(--color-brand-primary) !important;
        }
    }
    &--tertiary {
        outline-color: var(--color-brand-tertiary) !important;
        &::after {
            color: var(--color-brand-tertiary);
            background-color: var(--color-brand-tertiary);
        }
    }
}
