// dismissable banner

.banner {
    position: relative;
    padding: 1.5rem 3rem 1.5rem 0;
    background-color: var(--color-brand-secondary);

    &__inner {
        font-weight: 600;

        p {
            margin: 0;
        }
        a {
            color: inherit;
            text-decoration: underline;
            font-weight: 700;
            text-decoration-thickness: 2px;

            @include hover-focus {
                text-decoration: none;
            }
        }
        .icon {
            margin-right: 0.5rem;
            position: relative;
            top: -0.1em;
        }
    }

    &__close {
        @include button-reset;
        position: absolute;
        top: 50%;
        right: 0.5rem;
        padding: 1rem;
        transform: translateY(-50%);
        transition: transform 0.2s $animation-curve-default;

        @include hover-focus {
            transform: translateY(-50%) rotate(-180deg);
        }
    }

    @include mq(md) {
        padding: 1.5rem;

        &__inner {
            text-align: center;
        }
    }
}

.nav-open .banner {
    @include mq-down(lg) {
        display: none;
    }
}
