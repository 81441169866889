.projectlist {
    $p: &;
    text-align: left;

    &__item:not(:last-child) {
        margin-bottom: $spacing-y;

        @include mq(lg) {
            margin-bottom: $spacing-y * 2;
        }
    }

    &--two-cols {
        @include mq(md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;

            .project {
                height: 100%;
            }
        }

        #{$p}__item:not(:last-child) {
            margin-bottom: 1rem;

            @include mq(md) {
                margin-bottom: 0;
            }
        }
    }
}
