.vcard {
    display: flex;
    align-items: center;
    text-align: left;

    &__avatar {
        display: block;
        width: 60px;
        height: 60px;
        margin-right: 0.75rem;
        border-radius: 50%;
        object-fit: cover;
    }

    &__name {
        display: block;
        margin: 0;
        font-weight: 600;
        line-height: 1.2;

        // &::after {
        //     content: '';
        //     display: block;
        //     width: 18px;
        //     height: 5px;
        //     background-color: var(--color-brand-primary);
        // }
    }
    &__role {
        margin:0;
    }
}
