.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 4rem;

    &__item {
        padding-top: 56.25%;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: $box-shadow;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
    }
}
