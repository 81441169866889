.actionsteps > * + * {
    margin-top: 1.5rem;
}

.actionstep {
    font-size: 1.125rem; //18px
    
    @include mq(md) {
        font-size: 1.3125rem; //21px
    }

    &__summary {
        width: 100%;
        list-style-type: none;
        cursor: pointer;
        outline: none !important;

        &::marker {
            display: none;
        }

        &::-webkit-details-marker {
            display: none;
        }

        &:hover {
            color: var(--color-brand-primary);
            font-weight: 700;
        }

        details[open] & {
            font-weight: 700;
        }
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__indicator {
        display: inline-flex;
        flex: none;
        color: var(--color-brand-primary);
        background-color: var(--color-scale-25);
        border-radius: 50%;
        margin: -0.25em 1rem 0 0;
        transition: transform 0.25s $animation-curve-default;

        summary:hover &,
        details[open] & {
            color: $gray-lightest;
            background-color: var(--color-brand-primary);
        }
        details[open] & {
            transform: rotate(90deg);
        }
    }

    &__panel {
        padding: 0.5rem 0 0 3rem;
        max-width: 70ch;
    }
}
