.color-scheme {
    // BRAND COLORS
    &--primary {
        --color-scheme-base: #{$brand-primary};
        --color-scheme-accent: #fff;

        &-light {
            --color-scheme-base: #{lighten($brand-primary, 30%)};
            --color-scheme-accent: #{darken($brand-primary, 5%)};
        }
    }
    &--secondary {
        --color-scheme-base: #{$brand-secondary};
        --color-scheme-accent: #{$gray-dark};

        &-light {
            --color-scheme-base: #{lighten($brand-secondary, 37%)};
            --color-scheme-accent: #{darken($brand-secondary, 30%)};
        }
    }
    &--tertiary {
        --color-scheme-base: #{$brand-tertiary};
        --color-scheme-accent: #fff;

        &-light {
            --color-scheme-base: #{lighten($brand-tertiary, 28%)};
            --color-scheme-accent: #{darken($brand-tertiary, 15%)};
        }
    }

    &--dark {
        --color-scheme-base: #{$gray-darkest};
        --color-scheme-accent: #{$gray-light};

        &-light {
            --color-scheme-base: #{$gray-light};
            --color-scheme-accent: #{$gray-darkest};
        }
    }

    // STATUS COLORS
    &--info {
        --color-scheme-base: #{$state-info};
        --color-scheme-accent: #{lighten($state-info, 55%)};

        &-light {
            --color-scheme-base: #{lighten($state-info, 42%)};
            --color-scheme-accent: #{darken($state-info, 10%)};
        }
    }
    &--success {
        --color-scheme-base: #{$state-success};
        --color-scheme-accent: #{darken($state-success, 35%)};

        &-light {
            --color-scheme-base: #{lighten($state-success, 40%)};
            --color-scheme-accent: #{darken($state-success, 19%)};
        }
    }
    &--error {
        --color-scheme-base: #{$state-error};
        --color-scheme-accent: #{lighten($state-error, 55%)};

        &-light {
            --color-scheme-base: #{lighten($state-error, 50%)};
            --color-scheme-accent: #{darken($state-error, 10%)};
        }
    }
    &--warning {
        --color-scheme-base: #{$state-warning};
        --color-scheme-accent: #{darken($state-warning, 35%)};

        &-light {
            --color-scheme-base: #{lighten($state-warning, 40%)};
            --color-scheme-accent: #{darken($state-warning, 18%)};
        }
    }
}
