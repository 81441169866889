.topic-list {
    $spacing: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing;

    &__item {
        @extend %post-frame;
        font-size: 1.125rem;
        border-radius: 1px;
        line-height: 1;
        padding: 0.75rem 1rem;
        position: relative;

        & > a:before {
            content: '';
            position: absolute;
            inset: 0;
        }

        &.is-active {
            --frame-color: var(--color-scale-25);
            background-color: var(--frame-color);
        }
    }
}
