// Form Component

form {
    .req {
        position: relative;
        top: -0.125em;
        line-height: 0.5em;
        padding-left: 0.125em;
        color: $state-error;
    }
}

.form {
    &__feedback {
        margin-bottom: 1rem;

        .alert {
            font-size: 0.875rem;
            line-height: 1.2;
        }
    }
}

.formwidget {
    width: 100%;
    background-color: #fff;
    box-shadow: $box-shadow-elevated;
    max-width: #{map-get($container-max-widths, 'lg')};
    padding: 1rem;
    margin: 2rem auto;

    &--small {
        max-width: 480px;
        text-align: center;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}

.form--signup .btn--large {
    padding: 0.75rem 2rem 0.5rem 2rem;
}
