$postlist-gap: $spacing-x;

.postlist {
    display: flex;
    flex-wrap: wrap;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $postlist-gap;

    &__item {
        flex-basis: 100%;
        margin-bottom: $postlist-gap;
    }

    @include mq(lg) {
        grid-template-columns: 1fr 1fr;
        grid-gap: $postlist-gap * 2;

        margin: -$postlist-gap;

        &__item {
            display: flex;
            flex-basis: 50%;
            max-width: 50%;
            padding: $postlist-gap;
            margin: 0;

            &--fullwidth {
                grid-column: 1 / -1;
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }

    @supports (display: grid) {
        margin: 0;

        &__item {
            padding: 0 !important;
            margin: 0 !important;
            max-width: none !important;
        }
    }
}
