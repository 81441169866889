%post-frame {
    --frame-color: var(--color-scale-25);
    border: 1px solid var(--frame-color);

    &:where(:hover, :focus-within) {
        --frame-color: var(--color-brand-primary);
    }
}

.post {
    @extend %post-frame;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    padding: $spacing-x;
    background-color: var(--color-scale-0);
    transition: box-shadow 0.2s linear;
    text-align: left;

    &::before {
        content: '';
        display: block;
        width: 32px;
        height: 8px;
        background-color: var(--color-brand-secondary);
        margin-bottom: 0.5rem;
    }
    &--primary::before {
        background-color: var(--color-brand-primary);
    }
    &--tertiary::before {
        background-color: var(--color-brand-tertiary);
    }

    &__title {
        @include hyphenate;
        font-size: 1.75rem;
        margin-bottom: 0.25rem;
        text-wrap: balance;
    }
    &__link {
        @include stretched-link;
        color: var(--color-scale-100);
    }
    &__meta,
    &__description {
        margin-bottom: 1.5rem;
    }
    &__meta {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__description {
        max-width: 50ch;
        flex: 1;
    }
    &__image {
        margin-bottom: 1rem;

        > img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: $border-radius;
            box-shadow: 14px 14px 70px -10px rgba(0, 0, 0, 0.3);
        }
    }
    &__lang {
        display: inline-block;
        margin-left: 1rem;
        padding: 0.25rem 0.375rem;
        line-height: 1;
        color: darken($state-info, 10%);
        background-color: lighten($state-info, 45%);
        border: 1px solid lighten($state-info, 35%);
        border-radius: 0.25rem;
    }

    @include mq(md) {
        &__title {
            font-size: 2.1875rem;
        }
    }
}

// FEATURED POSTS

.post--featured.post--with-image {
    @include mq(lg) {
        padding-left: calc(25% + #{$spacing-x});
        margin-left: 25%;

        .post__image {
            margin: 0;
            position: absolute;
            left: -1rem;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 63%;
        }
    }
}

.post--featured:not(.post--with-image) {
    @include mq(lg) {
        padding: $spacing-x * 2;

        .post__title {
            font-size: 3rem; // 48px
        }
        .post__description {
            font-size: 1.625rem; //26px
            max-width: 60ch;
        }
    }
}
