.landinghero {
    display: grid;
    gap: 2rem;

    &__title {
        font-size: 2rem;
        margin-bottom: 1rem;
        text-wrap: pretty;
    }

    &__kicker {
        .icon {
            position: relative;
            top:-.025em;
        }
    }

    &__media {
        border-radius: 8px;
        overflow: hidden;
    }

    &__img {
        position: relative;
        aspect-ratio: 16 / 9;

        > img {
            @include coverall;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__rating {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
    }

    @include mq(lg) {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 4rem;

        &__title {
            font-size: 3rem;
        }
    }
}