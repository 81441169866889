$field-height: 58px;
$field-height-textarea: 180px;
$field-spacing: 1rem;
$field-padding: 1rem;
$field-padding-left-with-icon: 3.5rem;
$field-font-size: 1rem;
$field-label-font-size: 0.875rem;
$field-label-font-size-small: 0.75rem;
$field-bg-color: lighten($gray-light, 4%);
$field-label-color: $gray-dark;
$field-border-color: lighten($gray, 50%);
$field-border-color-focus: $brand-primary;

// Base Field Alignment Wrapper

%field {
    clear: both;
    display: block;
    position: relative;
    text-align: left;
    padding: $field-spacing 0;
}

// Inner Container: Essentially acts as the visual "field" box

%field__inner {
    position: relative;
    font-size: $field-font-size;
    height: $field-height;
    background-color: $field-bg-color;
    border: 1px solid $field-border-color;
    border-radius: 0;
    transition: border-color 0.2s $animation-curve-default,
        box-shadow 0.2s $animation-curve-default,
        background-color 0.2s $animation-curve-default;

    &:hover {
        border-color: darken($field-border-color, 50%);
    }

    .is-textarea & {
        height: $field-height-textarea;
    }

    .is-focused &,
    &:focus-within {
        border-color: $field-border-color-focus;
        background-color: #fff;
        box-shadow: 0 0 0 1px $field-border-color-focus;
    }

    .is-invalid & {
        border-color: $state-error;
        box-shadow: none;
    }

    .is-disabled &,
    .is-readonly & {
        background-color: $gray-lightest;
        border-color: $gray-light;
    }
}

// Actual Field Input

%field__input {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: $body-color;
    height: $field-height;
    outline: none;
    padding: 0 $field-padding;
    position: absolute;
    top: $field-font-size * 0.375;
    width: 100%;
    font-weight: 600;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    resize: none;

    .is-disabled & {
        color: $gray-light;
        -webkit-text-fill-color: transparent;
    }

    .is-textarea & {
        height: $field-height-textarea - 2px;
        padding-top: $field-label-font-size-small * 1.75;
        top: 0;
    }

    .has-icon & {
        padding-left: $field-padding-left-with-icon;
    }

    &:focus {
        outline: none;
    }

    &::-webkit-input-placeholder {
        color: transparent;
    }

    &:-moz-placeholder {
        color: transparent;
    }

    &::-moz-placeholder {
        color: transparent;
    }

    &:-ms-input-placeholder {
        color: transparent;
    }

    // Disable clear field icon for IE10+
    &::-ms-clear {
        height: 0;
        width: 0;
    }

    &:-webkit-autofill {
        // Expose a hook for JavaScript when auto fill is shown.
        // JavaScript can capture 'animationstart' events
        animation-name: onAutoFillStart;

        // Make the backgound color become yellow _really slowly_
        transition: background-color 50000s ease-in-out 0s;
    }

    &:not(:-webkit-autofill) {
        // Expose a hook for JS onAutoFillCancel
        // JavaScript can capture 'animationstart' events
        animation-name: onAutoFillCancel;
    }
}

// The Input's Floating Label

%field__label {
    display: block;
    position: absolute;
    width: 100%;
    padding: 0 $field-padding;
    margin: 0;

    color: $field-label-color;
    font-weight: 500;
    font-size: $field-label-font-size;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: $field-height;
    overflow: hidden;
    transform: translateY(0);
    transition: transform 0.2s ease, font-size 0.2s ease;

    .is-disabled & {
        color: $gray-light;
    }

    .has-icon & {
        padding-left: $field-padding-left-with-icon;
    }

    .field__input:-webkit-autofill ~ & {
        transition-duration: 0ms;
    }

    // Floating Label State

    // Separate Declaration, keep JS classes as fallback
    .field__input:not(:placeholder-shown) ~ &,
    .field__input:-webkit-autofill ~ & {
        transform: translateY(-1.15em);
        font-size: $field-label-font-size-small;
        pointer-events: none;
    }

    .is-focused &,
    .is-dirty & {
        transform: translateY(-1.15em);
        font-size: $field-label-font-size-small;
        pointer-events: none;
    }
}

// Optional Icon

%field__icon {
    display: block;
    position: absolute;
    top: 50%;
    left: $field-padding;
    transform: translateY(-50%);
    font-weight: normal;
}

// Optional Field Hint Text

%field__hint {
    font-size: 0.875rem;
    margin: 0.25rem $field-padding 0;
    color: $gray-dark;
    font-style: italic;
}

// Field Error Labels

%field__error {
    display: block;
    padding: 0.25rem 0.375rem;
    margin:0 !important;
    font-size: $field-label-font-size-small;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.25px;
    background-color: $state-error !important;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);

    position: absolute;
    bottom: 100%;
    left: -1px;
    right: -1px;
    transform: translateY(2px);

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

// We use these keyframe Animations as signals for JS, that's why they're empty.
// Do not rename them, they work to detect autofilled inputs.

@keyframes onAutoFillStart {
    from {
        /**/
    }
    to {
        /**/
    }
}

@keyframes onAutoFillCancel {
    from {
        /**/
    }
    to {
        /**/
    }
}

// Class Definition

.field {
    @extend %field;

    &__inner {
        @extend %field__inner;
    }
    &__label {
        @extend %field__label;
        cursor: text;
    }
    &__input {
        @extend %field__input;
    }
    &__hint {
        @extend %field__hint;
    }
    &__icon {
        @extend %field__icon;
    }
    &__error {
        @extend %field__error;
    }
}
