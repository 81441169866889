.package {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border: 2px solid var(--color-scale-25);
    background-color: var(--color-scale-0);
    position: relative;
    text-align: left;

    &__header {
        margin-bottom: 1.25rem;
    }
    &__title {
        font-size: 1.5rem;
        margin: 0;
    }
    &__subtitle {
        color: var(--color-scale-50);
        margin: 0;
    }

    &__teaser {
        margin: 0;
    }

    &__featurelist {
        flex: 1 0 auto;
        > * + * {
            margin-top: 0.5rem;
        }
    }
    &__feature {
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 0.75em;
            height: 0.25em;
            background-color: var(--color-brand-primary);
            position: absolute;
            top: 0.5em;
            left: -1.25rem;
        }
    }
    &__info {
        color: var(--color-scale-50);
        display: inline-block;
        margin-left: 0.25em;
        transform: translateY(-5%);

        &:hover {
            color: var(--color-brand-primary);
        }
    }
    &__price {
        font-weight: bold;
        font-size: 1.25rem;
        margin-top: 1em;
        margin-bottom: 0;
        line-height: 1;

        &--top {
            position: absolute;
            top: 2.125rem;
            right: 2rem;
            font-size: 1.5rem;
            text-align: right;
            margin: 0;
        }
    }
}

.package--highlighted {
    border-color: var(--color-brand-secondary);
    border-width: 3px;
    transform: scale(1.05);
    box-shadow: $box-shadow;
}

.package--minimal {
    background-color: var(--color-scale-25);

    .package__feature::before {
        display: none;
    }

    @include mq(lg) {
        grid-column: 1 / -1;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        padding-right: 130px;

        .package__header {
            margin: 0 2.5rem 0 0;
            display: flex;
            align-items: baseline;
        }
        .package__title {
            margin-right: 0.75rem;
        }
    }
}

.packagecontainer {
    margin-bottom: 7rem;

    &__title {
        margin-bottom: 2rem;
    }

    &__intro {
        font-size: 1.3125rem;
        line-height: 1.75;
        margin-bottom: 1.5em;
    }

    &__content {
        display: grid;
        grid-gap: 2rem;

        @include mq(lg) {
            grid-template-columns: repeat(3, 1fr);

            &--small {
                grid-template-columns: repeat(2, 1fr);
            }

            &--centered {
                padding-left: 15%;
                padding-right: 15%;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

.highlighted {
    font-weight: 700;
}
