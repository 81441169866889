.lightswitch {
    @include button-reset;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 2.8125rem;
    height: 2.8125rem;
    border-radius: 50%;
    color: inherit;

    @include mq(lg) {
        color: var(--color-scale-100);
        &:hover {
            background-color: var(--color-scale-25);
        }
    }

    &__icon {
        margin: auto;
        fill: currentColor;

        &__on {
            display: block;
        }
        &__off {
            display: none;
        }
    }
}
