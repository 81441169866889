.facepile {
    padding-left: 1rem;
    margin-bottom: 1rem;

    &__item {
        display: inline-block;
        margin-left: -1rem;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        border: 4px solid var(--color-scale-0);
        line-height: 1;
        box-sizing: content-box;
        background-color: var(--color-scale-25);

        @include mq(lg) {
            width: 5rem;
            height: 5rem;
        }
    }

    &__img {
        object-fit: cover;
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }
}
