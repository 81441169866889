/**
  A card that is intended to show a device-frame with a screenshot inside on top.
 */
.device-card {
    background-color: var(--color-scale-0);
    padding: 1rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow-card;
    text-align: left;

    @at-root :root[data-theme='dark'] & {
        border: 1px solid var(--color-scale-25);
    }

    @include mq(md) {
        padding: 2rem;
    }

    &__image {
        margin-block-start: calc(#{$device-card-image-overlap} * -1);
        height: 250px;

        img,
        picture {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__text {
        margin-block-start: 1.5em;
    }
}
